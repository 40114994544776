<template>
  <header
    class="
      w-auto
      h-16
      flex
      items-center
      justify-between
      border-b border-gray-200
      px-5
    "
  >
    <div class="flex items-center">
      <span
        v-if="isXSmall"
        class="text-gray-600 cursor-pointer mr-3"
        @click="toggleSidebar()"
      >
        <transition name="fade" mode="out-in">
          <i class="pi pi-bars text-lg"></i>
        </transition>
      </span>
      <div v-else></div>
      <!-- Languages Dropdown -->
      <Dropdown
        class="dropdown-locales"
        :options="locales"
        v-model="selectedLocale"
        optionLabel="name"
      >
        <template #value="slotProps">
          <div class="flex items-center">
            <img
              v-if="slotProps.value"
              class="w-6 rounded-sm h-4 focus:border-gray-900"
              :src="getImgUri(slotProps.value.code)"
            />
            <div class="px-2 uppercase font-semibold text-gray-600 text-sm">
              {{ $t("lang") }}
            </div>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex items-center">
            <img
              class="w-6 rounded-sm h-4 mr-2 mt-0.5"
              :src="getImgUri(slotProps.option.code)"
            />
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
      <!-- <span class="px-2"> {{ $t("message") }} </span> -->
    </div>
    <div class="flex gap-5 items-center">
      <messages-badge />

      <div v-if="currentUser" class="flex">
        <div
          class="grid gap-1"
          :class="
            $store.getters.isRTL ? 'text-left ml-2.5' : 'text-right mr-2.5'
          "
        >
          <span class="font-semibold capitalize">
            {{ currentUser.name }}
          </span>
          <span class="text-xs font-semibold text-gray-600 -mt-1.5">
            {{ currentUser.email }}
          </span>
        </div>
        <span
          class="
            w-10
            h-10
            text-center
            pt-1.5
            rounded-full
            bg-gray-500
            font-bold
            text-white text-lg
          "
        >
          {{ currentUser.name[0].toUpperCase() }}
        </span>
      </div>
    </div>
  </header>
</template>

<script>
import Avatar from "primevue/avatar";
import Dropdown from "primevue/dropdown";
import MessagesBadge from '@/components/widgets/MessagesBadge.vue'

import axios from "axios";

export default {
  props: ["hide", "isXSmall"],
  emits: ["toggle"],
  components: { Avatar, Dropdown, MessagesBadge },
  data() {
    return {
      hideSidebar: this.hide,
      selectedLocale: this.$store.state.locale,
      locales: this.$store.state.locales,
    };
  },
  methods: {
    getImgUri(img) {
      // let images = require.context("@/assets/flags", false);
      return ("/flags/" + img + ".jpg");
    },
    toggleSidebar() {
      this.$emit("toggle");
      this.hideSidebar = !this.hideSidebar;
    },
  },
  watch: {
    selectedLocale(v) {
      this.$store.state.locale = v;
      localStorage.setItem("locale", JSON.stringify(v));
      location.reload();
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
  mounted() {
    let token = localStorage.getItem("access_token");
    if (token != undefined) {
      axios
        .get("user")
        .then((response) => {
          this.$store.state.auth.currentUser = response.data;
        })
        .catch((error) => console.error(error));
    }
  },
};
</script>

<style scoped>
.icon-style {
  @apply flex p-2 items-center rounded-full bg-white shadow text-gray-500 text-base;
}
</style>
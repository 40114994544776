import axios from "axios"

export default {
    namespaced: true,
    state: {
        latest: [],
        newCount: 0,
        selectedItem: null,
    },
    actions: {
        getLatest({ state }) {
            axios.get("mails/latest")
                .then(response => {
                    state.latest = response.data.latest;
                    state.newCount = response.data.new_count
                })
                .catch()
        }
    }
}
<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import RadioButton from "primevue/radiobutton";
import MultiSelect from "primevue/multiselect";

import { useStore } from "vuex";
import axios from "axios";

const store = useStore();
const showPasswordDialog = ref(false)
const currentPassword = ref('')
const errors = ref({})
const errorMessage = ref()
const usersIds = ref([])
const success = ref()

const filters = reactive({
  userType: "bound",
  deviceStatus: "",
  deviceType: "",
});

const notificationTitle = ref("Welcome {name} 🤩");
const notificationBody = ref("Hey {name} 👋, Welcome to Sleepbytes 🥳.");
const selectedUsers = ref([]);
const exceptUsers = ref([]);
const users = ref([]);
const filtredUsers = ref([]);
const search = ref("");
const radioButton = ref("all");
const sending = ref(false);

watch(search, (v) => {
  filtredUsers.value = users.value.filter((e) =>
    (e.name + "" + e.email).includes(v)
  );
});

const userTypes = [
  { name: "All", value: "" },
  { name: "Have Device(s)", value: "bound" },
  { name: "Don't have device", value: "not_bound" },
];

const deviceStatuses = [
  { name: "Both", value: "" },
  { name: "Online", value: "online" },
  { name: "Offline", value: "offline" },
];

const deviceTypes = [
  { name: "All", value: "" },
  { name: "Sleep Monitor", value: "M800" },
  { name: "Aroma", value: "SA" },
];

watch(filters, async (nv) => {
  let devicesList = null;
  if (nv.userType === "not_bound") {
    filters.deviceStatus = "";
    filters.deviceType = "";
  }

  if (nv.deviceStatus !== "") {
    devicesList = store.state.devices.onlineDevices.join(",");
  }

  let body = { ...nv, devices: devicesList };

  fetchUsers(body);
});

watch(radioButton, () => {
  errors.value['users'] = null
})

function fetchUsers(body) {
  axios.get("all-users", { params: body }).then((response) => {
    users.value = response.data;
    filtredUsers.value = users.value;
    selectedUsers.value = [];
    exceptUsers.value = [];
  });
}

function clearSelection() {
  selectedUsers.value = [];
}

function setShowPasswordDialog(v) {
  errors.value['users'] = null
  if (v) {
    usersIds.value = [];
    if (radioButton.value == "all_except") {
      usersIds.value = users.value
        .filter((u) => !exceptUsers.value.includes(u))
        .map((u) => u.id);
    } else if (radioButton.value == "selected") {
      usersIds.value = selectedUsers.value.map((u) => u.id);
    } else {
      usersIds.value = users.value.map((u) => u.id);
    }

    if (usersIds.value.length == 0) {
      errors.value['users'] = 'please_select_users'
      return;
    }
  }
  showPasswordDialog.value = v
}

async function push() {
  errors.value = {}
  sending.value = true;
  success.value = null
  errorMessage.value = null
  let body = {
    users: usersIds.value,
    template: { title: notificationTitle.value, body: notificationBody.value },
    password: currentPassword.value
  };
  axios
    .post("push-user-notification", body)
    .then((response) => {
      setShowPasswordDialog(false)
      usersIds.value = [];
      sending.value = false;
      success.value = true
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 422) {
        errors.value = error.response.data.errors
      } else {
        errorMessage.value = error.response.data.message
      }
      sending.value = false;
    });
}

onMounted(() => {
  fetchUsers({});
});
</script>

<template>
  <div class="grid gap-3">
    <div v-if="success"
      class="rounded-lg border w-full flex gap-3 items-center p-3 border-green-600 bg-green-100 text-green-600">
      <i class="pi pi-info-circle"></i>
      <span>{{ $t('notification_success_message') }}</span>
      <div class="flex-1"></div>
      <button class="p-0 pi pi-times" @click="success = null"></button>
    </div>

    <Card class="border shadow-none">
      <template #title>{{ $t("advanced_filters") }}</template>
      <template #content>
        <div class="grid md:grid-cols-2 gap-3">
          <div class="grid">
            <label class="capitalize">{{ $t('users') }}</label>
            <Dropdown v-model="filters.userType" :options="userTypes" optionLabel="name" optionValue="value" />
          </div>

          <!-- <div class="grid">
            <label>{{ $t('device_connection') }}</label>
            <Dropdown :disabled="filters.userType == 'not_bound'" v-model="filters.deviceStatus" :options="deviceStatuses"
              optionLabel="name" optionValue="value" />
          </div> -->

          <div class="grid">
            <label class="capitalize">{{ $t('device_type') }}</label>
            <Dropdown :disabled="filters.userType == 'not_bound'" v-model="filters.deviceType" :options="deviceTypes"
              optionLabel="name" optionValue="value" />
          </div>
        </div>
      </template>
    </Card>

    <Card class="border shadow-none rounded-md">
      <template #title>
        <span class="capitalize">{{ $t('notification') }}</span>
      </template>
      <template #content>
        <div class="flex flex-col md:flex-row gap-2">
          <div class="flex-auto grid">
            <InputText type="text" :placeholder="$t('notification_title')" v-model="notificationTitle" class="mb-2" />
            <Textarea rows="5" cols="30" :placeholder="$t('notification_message')" v-model="notificationBody"
              class="mb-0.5"></Textarea>
            <em class="text-xs text-gray-500">
              {{ $t('notification_body_hint', { name: '{name}' }) }}
            </em>
          </div>
          <div class="relative max-w-md">
            <img src="@/assets/images/iphone.png" alt="" class="">
            <div class="absolute w-full top-20">
              <div class="rounded-lg border bg-white w-4/5 mx-auto px-2 py-3 flex gap-3">
                <img src="@/assets/images/sb-logo.svg" alt="" class="w-10 h-10">
                <div class="grid">
                  <span class="font-medium">{{ notificationTitle.replace("{name}", "Khalid") }}</span>
                  <span class="text-xs font-light">{{ notificationBody.replace("{name}", "Khalid") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>

    <Card class="border shadow-none rounded-md" :class="{ 'border-red-600': errors['users'] }">
      <template #title>
        <span class="capitalize">{{ $t('users') }}</span>
      </template>
      <template #content>
        <div class="grid gap-2 mt-3">
          <div class="flex space-x-2">
            <RadioButton id="all" value="all" v-model="radioButton" />
            <label for="all" class="font-sans font-semibold capitalize">{{ $t("all_filtred_users") }}</label>
          </div>
          <div class="grid">
            <div class="flex space-x-2">
              <RadioButton id="all_except" value="all_except" v-model="radioButton" />
              <label for="all_except" class="font-sans font-semibold capitalize">{{ $t("all_filtred_users_except")
              }}</label>
            </div>
            <div class="grid mt-2" v-if="radioButton == 'all_except'">
              <MultiSelect v-model="exceptUsers" :options="users" display="chip" optionLabel="email"
                :placeholder="$t('select_users')" :filter="true" />
            </div>
          </div>
          <div class="grid">
            <div class="flex space-x-2">
              <RadioButton id="selected" value="selected" v-model="radioButton" />
              <label for="selected" class="font-sans font-semibold capitalize">{{ $t("only_selected_users") }}</label>
            </div>
            <div class="grid mt-2" v-if="radioButton == 'selected'">
              <MultiSelect v-model="selectedUsers" :options="users" display="chip" optionLabel="email"
                :placeholder="$t('select_users')" :filter="true" />
            </div>
          </div>
        </div>
      </template>
    </Card>
    <small v-if="errors['users']" class="text-red-600 -mt-3">{{ $t(errors['users']) }}</small>

    <button @click="setShowPasswordDialog(true)" class="capitalize rounded-lg bg-blue-700 text-white">{{ $t('push')
    }}</button>
  </div>

  <Dialog v-model:visible="showPasswordDialog" :modal="true" class="w-full m-5 max-w-3xl" :closable="false">
    <template #default>
      <form @submit.prevent="push" class="grid place-content-center justify-items-center">
        <div v-if="errorMessage"
          class="rounded-lg border w-full flex gap-3 items-center p-3 mb-3 border-red-600 bg-red-100 text-red-700">
          <i class="pi pi-info-circle"></i>
          <span>{{ errorMessage }}</span>
        </div>
        <h2 class="text-lg font-bold">{{ $t('notification_dialog_title') }}</h2>
        <p class="p-3 text-center">{{ $t('notification_dialog_message1') }}</p>
        <input v-model="currentPassword" type="password" class="w-full rounded-lg border text-center"
          :class="{ 'border-red-600': errors['password'] }" :placeholder="$t('your_password')">
        <small v-if="errors['password']" class="text-red-600 justify-self-start">{{ $t(errors['password'][0]) }}</small>
        <p class="p-3 text-center font-medium">
          {{ $t('notification_dialog_message2').replace(":count", usersIds.length) }}
        </p>
        <div class="flex w-3/4 justify-center items-center gap-2">
          <button type="button" @click="setShowPasswordDialog(false)"
            class="flex-1 rounded-lg border-2 capitalize border-gray-500 text-gray-500 hover:text-gray-400 font-semibold">
            {{ $t('cancel') }}
          </button>

          <button type="submit" :disabled="currentPassword == '' || sending"
            class="flex-1 rounded-lg capitalize bg-blue-600 text-white hover:bg-blue-700 hover:disabled:bg-gray-300 font-semibold">
            {{ $t('send') }}
          </button>
        </div>
      </form>
    </template>
  </Dialog>
</template>

<style scoped>
label {
  @apply text-gray-500 text-sm mb-1;
}

.p-component .p-multiselect-header .p-checkbox-box {
  display: none;
}
</style>

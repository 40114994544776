<template>
  <div class="flex flex-col items-start justify-items-stretch h-full w-full">
    <div class="flex w-full items-center border-b px-4 h-14">
      <span class="text-gray-500 pr-4">
        <i class="pi pi-search text-sm"></i>
      </span>
      <input
        type="text"
        name="search"
        :placeholder="$t('placeholder_messages_search_input')"
        v-model="keyword"
        @change="search()"
        class="bg-white py-3 focus:outline-none w-full"
      />
      <div
        class="flex gap-3 items-center"
        v-if="$route.params.filter == 'trash'"
      >
        <i
          class="pi pi-replay icon"
          @click="restoreAll()"
          v-tooltip.left="$t('restore_all')"
        ></i>
        <i
          class="pi pi-trash icon"
          @click="emptyTrash()"
          v-tooltip.left="$t('empty_trash')"
        ></i>
      </div>
    </div>

    <div class="h-full w-full overflow-y-scroll bg-gray-50" id="scroll-div">
      <div v-if="isFirstLoad">
        <div
          class="rounded-m bg-white animate-pulse px-4 py-6 border-b"
          v-for="i in Array.from({ length: 10 })"
          :key="i"
        >
          <div class="flex items-start gap-1">
            <Avatar size="large" shape="circle" class="bg-gray-200" />
            <div class="flex-auto grid gap-1">
              <div class="bg-gray-200 p-2 w-64 rounded-full"></div>
              <div class="bg-gray-200 p-1.5 mb-1 w-64 rounded-full"></div>
              <div class="bg-gray-200 p-5 w-full rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="items?.length > 0">
        <div
          v-for="item in items"
          :key="item"
          class="
            p-4
            border-b
            cursor-pointer
            hover:shadow-md hover:bg-white hover:bg-opacity-70
          "
          @click="selectItem(item)"
        >
          <div class="flex items-start justify-between">
            <div class="flex items-center gap-2">
              <Avatar
                :label="item.sender_name[0].toUpperCase()"
                size="large"
                shape="circle"
                class="text-white font-semibold bg-gray-600"
              />
              <div class="grid">
                <span class="font-medium">{{ item.sender_name }}</span>
                <span class="text-gray-800 text-sm">
                  {{ item.email }}
                </span>
              <span class="text-gray-800 text-sm font-bold">
                {{ item.phone_number }}
              </span>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="rounded-full p-1"
                :class="
                  item.severity == 'low'
                    ? 'bg-yellow-400'
                    : item.severity == 'medium'
                    ? 'bg-blue-500'
                    : 'bg-red-500'
                "
              ></div>
              <span class="text-gray-400 font-light text-xs">
                {{ useMoment(item.created_at).startOf("minute").fromNow() }}
              </span>
            </div>
          </div>
          <div class="ml-14 mt-1 text-sm text-gray-500 font-light">
            <div class="truncate">
              {{ item.message }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center pt-5">
        <span class="text-sm font-semibold"> No messages </span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "primevue/avatar";
import Tooltip from "primevue/tooltip";
import moment from "moment";
import axios from "axios";
export default {
  components: { Avatar },
  directives: {
    tooltip: Tooltip,
  },
  emits: ["m-click", "refresh"],
  props: ["filter"],
  data() {
    return {
      isFirstLoad: true,
      loading: null,
      keyword: "",
      items: [],
      page: 1,
      selectedItem: null,
      hasFetchedAllData: false,
    };
  },
  methods: {
    useMoment(date) {
      return moment(date);
    },
    selectItem(item) {
      this.selectedItem = item;
      this.$emit("m-click", item);
    },
    async restoreAll() {
      await axios
        .put("mails/restore")
        .then(() => {
          this.$emit("refresh");
        })
        .catch();
    },
    async emptyTrash() {
      await axios
        .delete("mails/emptytrash")
        .then(() => {
          this.$emit("refresh");
        })
        .catch();
    },
    async fetch(f = "") {
      this.loading = true;
      let response = null;
      // console.log("mails?page=" + this.page + "&filter=" + f + "&search=" + this.keyword)
      await axios
        .get(
          "mails?page=" + this.page + "&filter=" + f + "&search=" + this.keyword
        )
        .then((res) => (response = res))
        .catch((err) => (response = err.response));
      this.loading = false;
      if (response.status == 200) {
        // console.log(response.data);
        this.items = this.items.concat(response.data.data);
        if (this.page >= response.data.last_page) {
          this.hasFetchedAllData = true;
        }
        this.page++;
      }
    },
    async firstFetch() {
      this.hasFetchedAllData = false;
      this.items = [];
      this.page = 1;
      this.isFirstLoad = true;
      await this.fetch(this.filter);
      this.isFirstLoad = false;
    },
    async search() {
      this.firstFetch();
    },
    async handleScroll() {
      let scroll = document.querySelector("#scroll-div");
      if (scroll.offsetHeight + scroll.scrollTop + 300 >= scroll.scrollHeight) {
        if (this.hasFetchedAllData || this.loading) {
          return;
        }
        this.fetch(this.filter);
      }
    },
  },
  computed: {
    selectedItemFromSatet() {
      return this.$store.state.messages.selectedItem
    }
  },
  watch: {
    selectedItemFromSatet(v) {
      this.selectItem(v)
    },
    filter: async function (v) {
      this.keyword = "";
      this.firstFetch();
    },
  },
  async mounted() {
    if(this.$store.state.messages.selectedItem) {
      this.selectItem(this.$store.state.messages.selectedItem)
    }
    document
      .querySelector("#scroll-div")
      .addEventListener("scroll", this.handleScroll);
    this.firstFetch();
  },
};
</script>

<style scoped>
.icon {
  @apply font-bold text-gray-700 cursor-pointer hover:text-black;
}
</style>
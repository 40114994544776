<template>
    <Reports :deviceId="deviceId" />
</template>
<script>
import Reports from "../reports/Reports.vue";
export default {
  components: { Reports },
  data() {
    return {
      deviceId: this.$route.params.device_id,
    };
  },
};
</script>

<style>
</style>
export default {
  lang: "en",
  android: "Android",
  bound: "bound",
  chart_bound_devices: "bound devices",
  bound_devices: "bound devices",
  chart_customers_by_phone_os: "customers by phone OS",
  chart_cutomers_by_city: "customers by city",
  chart_registred_users_by_day: "registered users by day",
  customer: "customers",
  dashboard: "dashboard",
  device: "devices",
  inbox: "inbox",
  ios: "iOS",
  message: "messages",
  report: "reports",
  today: "today",
  total: "total",
  user: "users",
  logout_confirm_message: "are you sure you want to logout?",
  no: "no",
  yes: "yes",
  placeholder_users_search: "Search by name, email or device name",
  checkbox_users_with_devices: "users with devices",
  email: "email",
  account_type: "account type",
  status: "status",
  registration_date: "registered",
  account_status: "account status",
  verified: "verified",
  non_verified: "non verified",
  admin: "admin",
  support: "support",
  pagination_footer_message: "Showing {a} from {b} {records}",
  role: "role",
  device_type: "device type",
  name: "name",
  device_status: "device status",
  not_bound: "not bound",
  search: "search",
  score_range: "score range",
  sleep_score: "sleep score",
  fall_asleep: "fall asleep",
  start_time: "start time",
  export: "export",
  placeholder_messages_search_input: "search - keyword",
  refresh: "refresh",
  pending: "pending",
  resolved: "resolved",
  unresolved: "unresolved",
  trash: "trash",
  priority: "priority",
  high: "high",
  medium: "medium",
  low: "low",
  move_to_trash: "move to trash",
  more: "more",
  make_as_high: "make as high",
  make_as_medium: "make as medium",
  make_as_low: "make as low",
  restore: "Restore",
  restore_all: "Restore all",
  delete_permanently: "Delete permanently",
  empty_trash: "Empty trash",
  no_devices: "No devices found.",
  no_reports: "No reports found.",
  side: "side",
  type: "type",
  last: "last",
  left: "left",
  right: "right",
  see_all: "see all",
  no_device_details: "No details for this device.",
  device_monitoring: "Device Monitoring",
  sleep_stages: "sleep stages",
  slp_stage_deep: "deep",
  slp_stage_medium: "medium",
  slp_stage_light: "light",
  slp_stage_awake: "awake",
  sleep_curve: "sleep curve",
  heart_rate: "heart rate",
  breath_rate: "breath rate",
  humidity: "humidity",
  temperature: "temperature",
  turn_over: "turn over",
  bad: "Bad",
  average: "Average",
  good: "Good",
  bravo: "Bravo",
  user_information: "user information",
  is_online: "is online",
  online: "online",
  offline: "offline",
  advanced_filters: "Advanced Filters",
  clear_filters: "Clear Filters",
  checkbox_only_online: "Online devices",
  online_devices: "Online devices",
  all_messages: "All messages",
  notify: "Notify",
  search_with_device_name: "Search with device name",
  change_password: "Change Password",
  change_your_account_password: "Change your account password",
  password_changed: "Password Changed Successfully",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  passwords_not_match: "Password and confirm password does not match",
  saving: "Saving...",
  incorrect_password: "Incorrect password",
  settings: "Settings",
  showing: "Showing",
  from: "from",
  checkbox_device_has_reports: "Only has reports",
  view_details: "View Details",
  mattress: "Mattress",
  sleepaid: "Sleepaid",
  number_of_nights: "Number of nights",
  number_of_laids: "Number of laids",
  no_details_available: "No details available",
  heartRate: "Heart Rate",
  breathRate: "Breath Rate",
  other: "Other",
  data_deletion: "Data deletion",
  account_deletion: "Account deletion",
  no_user_information: "No user information",
  device_information: "Device information",
  contact_information: "Contact Information",
  change_your_contact_information: "Change your contact information",
  edit: "edit",
  save: "save",
  contact_email: "Contact Email",
  phone_number: "Phone Number",
  support_email: "Support Email",
  push: "push",
  users: "users",
  notification: "notification",
  notification_title: "notification title",
  notification_message: "notification message",
  all_filtred_users: "all filtered users",
  all_filtred_users_except: "all filtered users, except",
  only_selected_users: "selected users only",
  select_users: "Select users",
  cancel: "Cancel",
  send: "Send",
  your_password: "Your password",
  notification_dialog_title: "Notification Confirmation",
  notification_dialog_message1: "Before proceeding, please confirm your action by entering your password:",
  notification_dialog_message2: "By confirming, you are authorizing the system to send notifications to the selected users (:count users). This action is irreversible.",
  "The password is incorrect.": "The password is incorrect.",
  notification_sent_summary: "Your notification has been sent to the selected users.",
  notification_sent_detail: "Notification Sent",
  notification_failed_summary: "Notification Failed",
  notification_failed_detail: "Notification failed to send to users",
  notification_body_hint: "To add the user's name within the text body, simply insert the '{name}' tag into your content.",
  help_url_ar: "Help Page Link (AR)",
  help_url_en: "Help Page Link (EN)",
  help_url_fa: "Help Page Link (FA)",
  help_url_fr: "Help Page Link (FR)",
  privacy_policy_url_ar: "Privacy Policy Link (AR)",
  privacy_policy_url_en: "Privacy Policy Link (EN)",
  privacy_policy_url_fa: "Privacy Policy Link (FA)",
  privacy_policy_url_fr: "Privacy Policy Link (FR)",
  advice: "Advice",
  created_at: "Created at",
  add_new: "Add New",
  confirmation: 'Confirmation',
  'Are you sure you want to delete this advice?': 'Are you sure you want to delete this advice?',
  language_code: 'Language code (en, fr, ar...)',
  content: 'Content',
  new_language: 'New Language',
  adv: "Advice",
  fun: "Fun",
  rel_info: "Information"
};

<template>
  <div v-if="user" class="grid xl:grid-cols-3 grid-cols-1 jus gap-4">
    <Card class="border">
      <template #content>
        <div class="grid justify-items-center">
          <Avatar
            class="font-bold capitalize"
            :label="user.name[0]"
            shape="circle"
            size="xlarge"
          />
          <span class="text-lg font-semibold capitalize">
            {{ user.name }}
          </span>
          <span class="font-light mb-1">
            {{ user.email }}
          </span>
          <Badge
            class="rounded"
            :value="
              user.account_type == 0
                ? 'Email'
                : user.account_type == 1
                ? 'Facebook'
                : user.account_type == 2
                ? 'Google'
                : user.account_type == 3
                ? 'Apple'
                : ''
            "
            :class="{
              'bg-green-200 text-green-700': user.account_type == 0,
              'bg-blue-200 text-blue-700': user.account_type == 1,
              'bg-red-200 text-red-700': user.account_type == 2,
              'bg-gray-300 text-gray-700': user.account_type == 3,
            }"
          />
        </div>
      </template>
    </Card>

    <div class="border shadow rounded-md xl:col-span-2">
      <DataTable
        class="p-datatable-sm"
        :value="user.devices"
        stripedRows
        responsiveLayout="scroll"
      >
        <template #header> {{$t('device', 2)}} ({{ user.devices_count }}) </template>
        <template #empty> {{$t("no_devices")}} </template>
        <Column field="device.name" :header="$t('device')" />
        <Column field="left_right" :header="$t('side')">
          <template #body="{ data }">
            <Badge
              v-if="data.device.single_or_double == 'D'"
              class="rounded"
              severity="info"
              :value="data.left_right == 0 ? $t('left') : $t('right')"
            />
            <span v-else>-</span>
          </template>
        </Column>
        <Column field="deviceName_user" :header="$t('name')" />
        <Column field="device_type_sst" :header="$t('type')" />
        <Column>
          <template #body="{ data }">
            <router-link
              :to="{ name: 'DeviceDetails', params: { id: data.device_id } }"
            >
              <i class="pi pi-external-link"></i>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="border shadow rounded-md xl:col-span-3">
      <DataTable
        columnResizeMode="fit"
        :value="user.reports"
        stripedRows
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="flex justify-between content-center">
            <div class="grid">
              <span>{{$t('report', 2)}} ({{ user.reports_count }})</span>
              <span class="text-xs text-gray-500">{{$t('last')}} 5 {{$t('report', 5)}}</span>
            </div>
            <router-link
            :to="{name: 'UserReports', params: {user_id: user.id}}"
              class="
                rounded
                border border-gray-700
                px-3
                py-1.5
                capitalize
                hover:bg-gray-200
              "
            >
              {{$t('see_all')}}
            </router-link>
          </div>
        </template>
        <template #empty> {{$t('no_reports')}} </template>
        <Column field="start_time" :header="$t('start_time')">
          <template #body="{ data }">
            {{ useMoment(data.start_time).format("D MMM YYYY, HH:mm") }}
          </template>
        </Column>
        <Column field="sleep_score" :header="$t('sleep_score')">
          <template #body="{ data }">
            <ProgressBar :value="data.sleep_score" />
          </template>
        </Column>
        <Column field="record_count" :header="$t('fall_asleep')" class="font-bold">
          <template #body="{ data }">
            {{ mins2hrs(data.record_count) }}
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <router-link
              :to="{ name: 'ReportDetails', params: { id: data.report_id } }"
            >
              <i class="pi pi-external-link"></i>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Avatar from "primevue/avatar";
import Card from "primevue/card";
import Badge from "primevue/badge";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressBar from "primevue/progressbar";
import moment from "moment";

export default {
  components: { Card, Avatar, Badge, DataTable, Column, ProgressBar },
  data() {
    return {
      userId: this.$route.params.id,
      user: null,
    };
  },
  methods: {
    useMoment(d) {
      return moment(d * 1000);
    },

    mins2hrs(min) {
      let hrs = Math.floor(min / 60);
      let mins = min % 60;
      if (hrs == 0) {
        return ("0" + mins).slice(-2) + "min";
      }
      return ("0" + hrs).slice(-2) + "hrs " + ("0" + mins).slice(-2) + "min";
    },
  },
  async mounted() {
    await axios
      .get(`users/${this.userId}/details`)
      .then((response) => {
        this.user = response.data;
      })
      .catch();
  },
};
</script>

<style>
.p-component.p-card .p-card-body .p-card-content {
  @apply p-0;
}
</style>
<template>
  <div class="h-full">
    <div class="flex border rounded-md h-full">
      <div class="bg-white md:w-56 rounded-l-md border-r">
        <div class="grid gap-y-2">
          <button
            class="p-2 capitalize bg-blue-600 rounded-md mx-2 my-2 text-white"
            @click="refresh()"
          >{{ $t("refresh") }}</button>
          <router-link
            v-for="item in menuItems"
            :key="item"
            class="item-style"
            :to="{ name: 'Messages', params: { filter: item.filter } }"
            :class="filter == item.filter ? 'item-active' : ''"
          >
            <div class="flex items-center justify-center md:justify-start">
              <i class="pi md:mx-1.5 font-semibold" :class="item.icon"></i>
              <span
                class="text-sm capitalize font-semibold leading-none hidden md:block"
              >{{ $t(item.name) }}</span>
            </div>
          </router-link>
          <div class="mb-6"></div>
          <span
            class="px-0.5 md:px-5 md:py-2.5 text-xs md:text-sm uppercase font-medium text-gray-300"
          >{{ $t("priority") }}</span>
          <router-link
            v-for="priority in priorities"
            :key="priority"
            :to="{ name: 'Messages', params: { filter: priority.filter } }"
            class="p-3 mx-1 rounded-md md:rounded-none md:py-2.5 md:px-5 md:m-0 md:border-l-2 md:text-black md:bg-transparent bg-opacity-20"
            :class="
              filter == priority.filter
                ? [priority.color, priority.bg, priority.border]
                : 'border-transparent'
            "
          >
            <div class="flex items-center justify-center md:justify-start">
              <div class="p-1 rounded-full md:mx-1.5" :class="priority.bg"></div>
              <span
                class="text-sm capitalize font-medium leading-none hidden md:block"
              >{{ $t(priority.name) }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="flex-auto relative overflow-hidden">
        <messages-list
          class="absolute"
          @m-click="selectItem"
          @refresh="refresh()"
          :filter="filter"
          ref="messageslist"
        />
        <transition name="show">
          <message-item
            v-if="selectedMessage"
            class="absolute"
            :item="selectedMessage"
            @close="close()"
            @refresh="refresh()"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import MessagesList from "@/components/messages/MessagesList.vue";
import MessageItem from "@/components/messages/MessageItem.vue";
export default {
  components: { MessagesList, MessageItem },
  props: ["filter"],
  data() {
    return {
      selectedMessage: null,
      menuItems: [
        {
          name: "all_messages",
          filter: "",
          icon: "pi-inbox",
        },
        {
          name: "pending",
          filter: "unresolved",
          icon: "pi-clock",
        },
        {
          name: "resolved",
          filter: "resolved",
          icon: "pi-check-square",
        },
        // {
        //   name: "Starred",
        //   filter: "starred",
        //   icon: "pi-star-o",
        // },
        {
          name: "trash",
          filter: "trash",
          icon: "pi-trash",
        },
      ],
      priorities: [
        {
          name: "high",
          color: "text-red-600",
          bg: "bg-red-600",
          border: "md:border-red-600",
          filter: "high",
        },
        {
          name: "medium",
          color: "text-blue-600",
          bg: "bg-blue-600",
          border: "md:border-blue-600",
          filter: "medium",
        },
        {
          name: "low",
          color: "text-yellow-400",
          bg: "bg-yellow-400",
          border: "md:border-yellow-400",
          filter: "low",
        },
      ],
    };
  },
  methods: {
    selectItem(selectedItem) {
      this.selectedMessage = selectedItem;
    },
    close() {
      this.selectedMessage = null;
      this.$store.state.messages.selectedItem = null
    },
    refresh() {
      this.close();
      this.$refs.messageslist.firstFetch();
    },
  },
  watch: {
    filter: async function (v) {
      this.close();
    },
  },
  unmounted() {
    this.$store.state.messages.selectedItem = null
  }
};
</script>

<style scoped>
.item-style {
  @apply p-3 mx-1 rounded-md md:rounded-none md:py-2.5 md:px-5 md:m-0 
  md:border-l-2 md:border-transparent;
}

.item-active {
  @apply md:border-blue-600 md:bg-transparent text-blue-600 bg-blue-100;
}

.show-enter-from,
.show-leave-to {
  transform: translateX(100%);
}

.show-enter-active,
.show-leave-active {
  transition: all 300ms ease;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/admin/dashboard/Dashboard.vue'
import Devices from '../views/admin/devices/Devices.vue'
import DeviceDetails from '../views/admin/devices/DeviceDetails.vue'
import DeviceReports from '../views/admin/devices/DeviceReports.vue'
import Users from '../views/admin/users/Users.vue'
import UserReports from '../views/admin/users/UserReports.vue'
import UserDetails from '../views/admin/users/UserDetails.vue'
import Notify from '../views/admin/users/Notify.vue'
import Reports from '../views/admin/reports/Reports.vue'
import ReportDetails from '../views/admin/reports/ReportDetails.vue'
import Messages from '../views/admin/messages/Messages.vue'
import Settings from '../views/admin/settings/Settings.vue'
import Contact from '../views/Contact.vue'
import AdviceIndex from '../views/admin/advice/Index.vue'
import AdviceCreate from '../views/admin/advice/Create.vue'
import Login from '../views/auth/Login.vue'
import store from '../store';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        redirect: 'dashboard',
        children: [{
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/devices',
                name: 'Devices',
                component: Devices,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/devices/:id',
                name: 'DeviceDetails',
                component: DeviceDetails,
                props: true,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/users',
                name: 'Users',
                component: Users,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/notify/users',
                name: 'Notify',
                component: Notify,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/users/:id',
                name: 'UserDetails',
                component: UserDetails,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/reports/user/:user_id',
                name: 'UserReports',
                component: UserReports,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/reports/device/:device_id',
                name: 'DeviceReports',
                component: DeviceReports,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/reports',
                name: 'Reports',
                component: Reports,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/reports/:id',
                name: 'ReportDetails',
                component: ReportDetails,
                props: true,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/messages/:filter?',
                name: 'Messages',
                component: Messages,
                props: true,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/settings',
                name: 'Settings',
                component: Settings,
                props: true,
                beforeEnter: async function(to, from, next) {
                    if (!(await store.getters['isAuthenticated'])) {
                        return next({ name: "Login" })
                    }
                    return next();
                }
            },
            {
                path: '/advice',
                children: [
                    {
                        path: '',
                        name: 'advice.index',
                        component: AdviceIndex,
                        beforeEnter: async function(to, from, next) {
                            if (!(await store.getters['isAuthenticated'])) {
                                return next({ name: "Login" })
                            }
                            return next();
                        }
                    },
                    {
                        path: '/create',
                        name: 'advice.create',
                        component: AdviceCreate,
                        beforeEnter: async function(to, from, next) {
                            if (!(await store.getters['isAuthenticated'])) {
                                return next({ name: "Login" })
                            }
                            return next();
                        }
                    },
                    {
                        path: '/edit/:id',
                        name: 'advice.edit',
                        component: AdviceCreate,
                        props: true,
                        beforeEnter: async function(to, from, next) {
                            if (!(await store.getters['isAuthenticated'])) {
                                return next({ name: "Login" })
                            }
                            return next();
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: async function(to, from, next) {
            if (await store.getters['isAuthenticated']) {
                return next({ name: "Dashboard" })
            }
            return next();
        }
    }, 
    {
        path: '/ibedSalidor/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/404',
        name: '404',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

export default router
<template>
    <div class="border bg-white rounded-lg shadow py-5">
        <div class="px-10">
            <h1 class="text-xl font-semibold mb-1">{{ $t("change_password") }}</h1>
            <h1 class="text-md font-semibold text-gray-400">
                {{ $t("change_your_account_password") }}
            </h1>
        </div>
        <hr class="my-6" />
        <div v-if="success" class="px-10 pb-6">
            <div class="
            p-5
            rounded-lg
            bg-green-400 bg-opacity-50
            text-green-600
            font-semibold
            flex
            justify-between
          ">
                <div class="flex items-center gap-2">
                    <i class="bi bi-info-circle-fill text-lg leading-none"></i>
                    <span class="leading-none">{{ $t("password_changed") }}</span>
                </div>
                <i class="bi bi-x-lg font-bold justify-self-end cursor-pointer" @click="success = null"></i>
            </div>
        </div>
        <form class="grid px-10" @submit.prevent="changePassword()">
            <div class="mb-3">
                <label class="text-sm font-semibold mb-2">{{ $t("current_password") }}</label>
                <input type="password" class="border border-gray-300 bg-gray-50 rounded-lg p-2.5 w-full" :class="{
                    'border-red-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-red-500':
                        errors.old_password,
                }" placeholder="Enter your current password" v-model="oldPassword" />
                <small v-if="errors.old_password" class="text-red-500">
                    {{ $t(errors.old_password) }}
                </small>
            </div>
            <div class="mb-3">
                <label class="text-sm font-semibold mb-2">{{ $t("new_password") }}</label>
                <input type="password" class="border bg-gray-50 border-gray-300 rounded-lg p-2.5 w-full" :class="{
                    'border-red-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-red-500':
                        errors.new_password,
                }" placeholder="Enter new password" v-model="newPassword" />
                <small v-if="errors.new_password" class="text-red-500">
                    {{ errors.new_password }}
                </small>
            </div>
            <div class="mb-6">
                <label class="text-sm font-semibold mb-2">{{ $t("confirm_password") }}</label>
                <input type="password" class="border border-gray-300 bg-gray-50 rounded-lg p-2.5 w-full" :class="{
                    'border-red-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-red-500':
                        errors.new_password_confirmation || !isPasswordsMatch,
                }" placeholder="Confirm new password" v-model="newPasswordConfirmation" />
                <ul class="mx-2">
                    <li>
                        <small v-if="errors.new_password_confirmation" class="text-red-500">
                            {{ errors.new_password_confirmation }}
                        </small>
                    </li>
                    <li>
                        <small v-if="!isPasswordsMatch" class="text-red-500">
                            {{ $t("passwords_not_match") }}
                        </small>
                    </li>
                </ul>
            </div>
            <div class="mb-3">
                <button class="text-white font-bold py-2 px-4 rounded-lg flex items-center gap-2" :class="{
                    'bg-blue-500 hover:bg-blue-700 ': !isDisabled && !saving,
                    'bg-gray-300': isDisabled || saving,
                }" :disabled="isDisabled || saving">
                    <i v-if="saving" class="pi pi-spin pi-spinner"></i>
                    {{ saving ? $t("saving") : $t("change_password") }}
                </button>
            </div>
        </form>
    </div>
</template>
  
<script setup>
import axios from "axios";
import { computed, ref } from "vue";

const oldPassword = ref("")
const newPassword = ref("")
const newPasswordConfirmation = ref("")
const errors = ref({})
const success = ref(null)
const saving = ref(false)

const isDisabled = computed(() => {
    return (
        !newPassword.value || !newPasswordConfirmation.value || !oldPassword.value
    );
})

const isPasswordsMatch = computed(() => {
    return newPassword.value === newPasswordConfirmation.value;
})

function changePassword() {
    if (!isPasswordsMatch.value) {
        return;
    }
    let payload = {
        old_password: oldPassword.value,
        new_password: newPassword.value,
        new_password_confirmation: newPasswordConfirmation.value,
    };
    saving.value = true;
    axios
        .post("/password/change", payload)
        .then((response) => {
            console.log(response.data);
            errors.value = {};
            success.value = true;
            saving.value = false;
        })
        .catch((error) => {
            errors.value = error.response.data;
            success.value = false;
            console.log(error.response.data);
            saving.value = false;
        });
}
</script>
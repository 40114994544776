<template>
  <main class="h-screen overflow-hidden relative">
    <div class="flex items-start justify-between">
      <sidebar
          v-if="!isXSmall"
          :class="{ hidden: hideSidebar }"
          :isSmall="isSmall"
      />
      <div
          v-else
          class="absolute bg-black bg-opacity-30 h-screen w-full z-50"
          :class="{ hidden: hideAbsoluteSidebar }"
          @click="toggleSidebar()"
      >
        <sidebar :isSmall="false"/>
      </div>
      <div class="grid w-full">
        <navbar @toggle="toggleSidebar()" :isXSmall="isXSmall"/>
        <div class="overflow-auto h-screen px-5 pt-5 pb-20">
          <router-view/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "./layouts/Navbar.vue";
import Sidebar from "./layouts/Sidebar.vue";

export default {
  components: {Sidebar, Navbar},
  data() {
    return {
      hideSidebar: false,
      hideAbsoluteSidebar: true,
      isSmall: window.innerWidth < 1024,
      isXSmall: window.innerWidth < 950,
    };
  },
  methods: {
    toggleSidebar() {
      if (!this.isXSmall) {
        this.hideSidebar = !this.hideSidebar;
      } else {
        this.hideAbsoluteSidebar = !this.hideAbsoluteSidebar;
      }
    },
  },

  created() {
    window.addEventListener("resize", () => {
      this.isSmall = window.innerWidth < 1024;
      this.isXSmall = window.innerWidth < 950;
    });
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.isSmall = window.innerWidth < 1024;
      this.isXSmall = window.innerWidth < 950;
    });
  },
};
</script>
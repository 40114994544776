<template>
  <apexchart type="line" :series="series" :options="options" />
</template>
<script>
import locales from "@/locales/apexcharts";
export default {
  props: ["labels", "series", "color"],
  data() {
    return {
      options: {
        grid: { show: false },
        labels: this.labels,
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: [this.color ?? "#000"],
        xaxis: {
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false },
          tooltip: { enabled: false },
        },
        legend: { show: false },
        stroke: {
          curve: "smooth",
          //   width: 3,
        },
        yaxis: { show: false },
        tooltip: {
          enabled: true,
          style: {
            fontFamily: this.$store.getters.isRTL ? "Almarai, sans-serif" : "",
          },
        },
      },
    };
  },
};
</script>
<style lang="">
</style>
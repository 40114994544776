<script>
import Password from "primevue/password";
import InputText from "primevue/inputtext";

import axios from "axios";

export default {
  components: {Password, InputText},
  data() {
    return {
      loading: null,
      status: null,
      message: null,
      form: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      let response = null;
      await axios
          .post("login", this.form)
          .then((res) => (response = res))
          .catch((err) => (response = err.response));
      if (response.status == 200) {
        this.status = "success";
        this.message = "Welcome back";
        for (let d in response.data) {
          localStorage.setItem(d, response.data[d]);
        }
        location.reload();
        // this.$router.replace({name: 'Dashboard'})
      } else if (response.status == 422) {
        this.status = "error";
        this.message = response.data.status;
      } else {
        console.log(response.data);
        this.status = "error";
        this.message = "Server error";
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="w-full h-screen content-center xl:grid grid-cols-3">
    <!-- <img src="@/assets/images/sb-logo.svg" class="w-5" /> -->
    <div
        class="hidden col-span-2 xl:grid grid-rows-6 h-screen bg-gray-50 bg-opacity-40"
    >
      <div class="row-span-1">
        <img alt="" class="w-52 p-5" src="@/assets/images/sb-lg-logo.svg"/>
      </div>
      <div class="row-span-4 flex items-center justify-center">
        <img alt="" class="" src="@/assets/images/sleep.png" width="800"/>
      </div>
    </div>
    <div class="flex flex-col h-full">
      <img alt="" class="w-52 p-5 xl:hidden" src="@/assets/images/sb-lg-logo.svg"/>
      <div class="md:p-10 flex items-center justify-center h-full">
        <div class="md:w-1/2 mx-auto xl:w-full">
          <div class="text-xl font-semibold gap-1">
            Welcome to Sleepbytes Admin Panel.
          </div>
          <div class="">Please sign-in to your account.</div>
          <div class="mt-8">
            <div v-if="status === 'error'" class="alert-error">
              <i class="pi pi-exclamation-triangle text-lg"></i>
              <span>{{ message || "Please enter your credentials." }}</span>
              <button @click="status = null" class="ml-auto">
                <i class="pi pi-times text-lg"></i>
              </button>
            </div>
            <form @submit.prevent="submit" class="p-fluid grid gap-4">
              <div class="rounded-lg border w-full flex items-center focus-within:outline focus-within:outline-1">
                <input type="email" v-model="form.email" class="outline-none w-full">
                <i class="pi pi-envelope px-2 text-gray-500"/>
              </div>
              <div class="field">
                <div class="p-float-label">
                  <Password
                      id="password"
                      :feedback="false"
                      v-model="form.password"
                      :class="{ 'p-invalid': false }"
                      class="border rounded-lg focus-within:outline focus-within:outline-1"
                      toggleMask
                  />
                </div>
                <small v-if="false" class="p-error"> {{}} </small>
              </div>
              <button
                  type="submit"
                  :label="loading ? 'Processing...' : 'Login'"
                  class="bg-blue-600 hover:bg-blue-700 mt-2 text-white"
                  :disabled="loading"
              >
                <i v-if="loading" class="pi pi-spinner pi-spin"></i>
                <span>{{ loading ? "Processing..." : "Login" }}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alert-error {
  @apply bg-red-400 text-red-600 border-l-8 border-red-600 bg-opacity-30
  px-3 py-2 rounded font-semibold
  mb-6 flex gap-3 items-center;
}


/* .sst-login-span {
  @apply rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm;
}

.sst-login-input {
  @apply rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 
    shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent;
}

.sst-login-button {
  @apply py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in 
    duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg;
}

.sst-login-text {
  @apply self-center mb-0 text-xl font-light text-gray-600 sm:text-2xl;
}

.sst-login-card {
  @apply flex flex-col w-full max-w-md px-4 py-8 border rounded-lg 
   sm:px-6 md:px-8 lg:px-10 bg-gray-50;
}

.alert-error {
  @apply p-3 mb-4 bg-red-100 border-l-4 border-red-700 text-red-800 text-sm rounded-sm;
}

.alert-success {
  @apply p-3 mb-4 bg-green-100 border-l-4 border-green-700 text-green-800 text-sm rounded-sm;
} */
</style>

<script setup>
import axios from "axios";
import { ref, watch } from "vue";

const responseError = ref(null);
const responseSuccess = ref(null);
const errors = ref({});
const loading = ref(false);
const form = ref({
  sender_name: null,
  email: null,
  reason: "other",
  message: null,
});

function validateForm() {
  errors.value = {};
  // validate name
  if (form.value.sender_name == null || form.value.sender_name == "") {
    errors.value["name"] = "The name field is required.";
  }
  // validate email
  if (form.value.email == null || form.value.email == "") {
    errors.value["email"] = "The email field is required.";
  } else if (
    !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(form.value.email)
  ) {
    errors.value["email"] = "The email is not valid.";
  }
  // validate message
  if (form.value.message == null || form.value.message == "") {
    errors.value["message"] = "The message field is required.";
  }
  // return true if no errors, false otherwise
  return Object.keys(errors.value).length == 0;
}

watch(form.value, () => {
  validateForm();
});

async function submit() {
  loading.value = true;
  responseError.value = null;
  if (validateForm()) {
    await axios
      .post("contact", form.value)
      .then((res) => {
        form.value.message = null;
        responseSuccess.value = res.data.message;
        errors.value = {}
      })
      .catch((err) => {
        responseError.value = err.message;
      });
  }
  loading.value = false;
}
</script>

<template>
  <form
    @submit.prevent="submit"
    class="grid place-content-center h-full p-20 container mx-auto gap-5"
  >
    <h1 class="text-4xl font-semibold mb-5">iBed Salidor Contact Support</h1>

    <div
      v-if="responseError"
      class="rounded-lg border border-red-600 bg-red-600 bg-opacity-20 text-red-600 p-4 text-center font-medium"
    >
      {{ responseError }}
    </div>

    <div
      v-if="responseSuccess"
      class="rounded-lg border border-green-600 bg-green-600 bg-opacity-20 text-green-600 p-4 text-center font-medium"
    >
      {{ responseSuccess }}
    </div>

    <div class="flex flex-col">
      <label for="">Name</label>
      <input
        v-model="form.sender_name"
        type="text"
        placeholder="Your Name"
        :class="errors['name'] ? 'border-red-600' : 'border-neutral-500'"
      />
      <small v-if="errors['name']" class="text-red-600">{{
        errors["name"]
      }}</small>
    </div>
    <div class="flex flex-col">
      <label for="">Email Address</label>
      <input
        v-model="form.email"
        type="email"
        placeholder="Your Email Address"
        :class="errors['email'] ? 'border-red-600' : 'border-neutral-500'"
      />
      <small v-if="errors['email']" class="text-red-600">{{
        errors["email"]
      }}</small>
    </div>
    <div class="flex flex-col">
      <label for="">Reason</label>
      <select
        v-model="form.reason"
        :class="errors['reason'] ? 'border-red-600' : 'border-neutral-500'"
      >
        <option value="account_deletion">Account Deletion</option>
        <option value="data_deletion">Data Deletion</option>
        <option value="other" selected>Other</option>
      </select>
      <small v-if="errors['reason']" class="text-red-600">{{
        errors["reason"]
      }}</small>
    </div>
    <div class="flex flex-col">
      <label for="">Message</label>
      <textarea
        v-model="form.message"
        placeholder="Your Message"
        class="h-60"
        :class="errors['message'] ? 'border-red-600' : 'border-neutral-500'"
      ></textarea>
      <small v-if="errors['message']" class="text-red-600">{{
        errors["message"]
      }}</small>
    </div>

    <button
      type="submit"
      :disabled="loading"
      class="bg-blue-800 rounded-lg p-3 text-center text-white hover:bg-blue-900 disabled:hover:bg-gray-300"
    >
      Send
    </button>
  </form>
</template>

<style scoped>
input,
select,
textarea {
  @apply rounded-lg border p-3;
}
</style>

<template>
  <!-- Advanced Filters Card -->
  <div class="border rounded-md border-gray-300 shadow-sm mb-3 p-5">
    <div class="flex gap-3 mb-4 justify-between justify-items-center">
      <div class="text-2xl text-gray-700">{{ $t("advanced_filters") }}</div>
      <button
        class="py-1.5 px-3 rounded border border-gray-900 font-semibold"
        @click="resetFilters"
      >
        <span class="text-sm">{{ $t("clear_filters") }}</span>
      </button>
    </div>
    <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-3 mb-2">
      <Dropdown
        class="p-inputtext-sm capitalize border text-start"
        v-model="selectedType"
        :options="types"
        optionLabel="name"
        :placeholder="$t('device_type')"
        @change="filter()"
        showClear
      />
      <Dropdown
        class="p-inputtext-sm capitalize border text-start"
        v-model="selectedStatus"
        :options="status"
        optionLabel="name"
        optionValue="key"
        :placeholder="$t('device_status')"
        @change="filter()"
        showClear
      />
      <div class="p-field-checkbox flex items-center gap-1 capitalize">
          <input type="checkbox"
              v-model="$store.state.devices.onlyHasReports"
              :binary="true"
              name="only_has_device"
              id="only_has_device"
              @change="filter()"
          />
          <label for="only_has_device">
            {{
              $t("checkbox_device_has_reports")
            }}
          </label>
        </div>
    </div>
  </div>

  <span class="flex items-center my-5 bg-white rounded-lg shadow-sm border">
    <i class="pi pi-search ps-4" />
    <input
      class="w-full outline-none"
      type="text"
      :placeholder="$t('search_with_device_name')"
      v-model="search"
      @change="filter"
    />
  </span>
  <DevicesDataTable
    :isLoading="loading"
    :devices="devices"
    @onPage="onPage($event)"
  />
</template>

<script>
import Dropdown from "primevue/dropdown";
import axios from "axios";
import DevicesDataTable from "@/components/devices/DevicesDataTable.vue";

export default {
  components: {
    Dropdown,
    DevicesDataTable,
  },
  data() {
    return {
      loading: false,
      search: this.$route.query.search ?? "", //filter
      page: Number(this.$route.query.page ?? 1),
      totalCount: null,
      perPage: null,
      devices: null,
      selectedType: this.$store.state.devices.type,
      types: [
        { name: "Sleep Monitor", code: "M800" },
        { name: "Aroma", code: "SA" },
      ],
      selectedStatus: this.$store.state.devices.status,
      status: [
        { name: this.$t("bound"), key: "bound" },
        { name: this.$t("not_bound"), key: "not_bound" },
      ],
    };
  },
  watch: {
    selectedType(v) {
      this.$store.commit("devices/setType", v);
    },
    selectedStatus(v) {
      console.log(v);
      this.$store.commit("devices/setStatus", v);
    },
  },
  methods: {
    resetFilters() {
      this.selectedType = null;
      this.selectedStatus = null;
      this.fetch();
    },
    onPage(event) {
      this.page = event.page + 1;
      this.$router.replace({
        name: "Devices",
        query: { ...this.$route.query, page: event.page + 1 },
      });
      this.fetch();
    },
    exportCSV(event) {
      this.$refs.dt.exportCSV();
    },
    filter() {
      this.page = 1;
      this.$router.replace({
        name: "Devices",
        query: { search: this.search },
      });
      this.fetch();
    },
    async fetch() {
      this.loading = true;
      let response = null;
      let url = "devices?page=" + this.page + "&search=" + this.search;
      if (this.selectedStatus) {
        url += "&status=" + this.selectedStatus;
      }
      if (this.selectedType) {
        url += "&type=" + this.selectedType.code;
      }
      if (this.$store.state.devices.onlyOnline) {
        url +=
          "&online=" + JSON.stringify(this.$store.state.devices.onlineDevices);
      }
      if (this.$store.state.devices.onlyHasReports) {
        url += "&has_reports=" + this.$store.state.devices.onlyHasReports;
      }
      await axios
        .get(url)
        .then((res) => (response = res))
        .catch((err) => (response = err.response));
      if (response.status == 200) {
        this.page = response.data.current_page;
        this.totalCount = response.data.total;
        this.perPage = response.data.per_page;
        this.devices = response.data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style>
</style>
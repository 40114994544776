<script setup>
defineProps(['message'])
const emit = defineEmits(['close'])
</script>

<template>
  <div v-if="message" class="rounded-lg bg-red-500 bg-opacity-10 py-4 px-6 flex items-center gap-3 text-red-600 border-red-600 border">
    <i class="pi pi-ban text-xl"></i>
    <span class="text-lg">{{ message }}</span>
    <button @click="emit('close')" class="pi pi-times p-0 ms-auto"></button>
  </div>
</template>
<template>
  <div class="grid">
    <div class="flex gap-3 justify-between">
      <span class="text-sm font-medium">{{ $t(dataType) }}</span>
      <span class="text-red-700"> {{ data[data.length - 1]?.y }} BPM </span>
    </div>
    <apexchart
      type="line"
      height="300"
      class="w-full"
      ref="chart"
      :options="chartOptions"
      :series="[{ data: data }]"
    ></apexchart>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed } from "vue";
import mqtt from "mqtt";

const props = defineProps(["topics", "dataType", "onStatusUpdated"]);
const emits = defineEmits("statusUpdated");

const realtimeData = ref([]);
const chartOptions = ref({
  chart: {
    id: "realtime",
    height: 350,
    type: "line",
    animations: {
      enabled: true,
      easing: "linear",
      dynamicAnimation: { speed: 500 },
    },
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  dataLabels: { enabled: false },
  stroke: { curve: "smooth", width: 3 },
  title: {
    // text: "Dynamic Updating Chart",
    align: "left",
  },
  markers: { size: 0 },
  yaxis: { max: 100, min: 0 },
  xaxis: { type: "datetime" },
  legend: { show: false },
});

const data = ref([]);

const realtimeDataTopic = computed(
  () => props.topics["realtime_data_upload_th_response"]
);
const deviceConnectionTopic = computed(
  () => props.topics["device_connection_event_subscribeEvent"]
);
const viewRealtimeDataRequest = computed(
  () => props.topics["view_realtime_data_request"]
);
const viewRealtimeDataResponse = computed(
  () => props.topics["view_realtime_data_response"]
);
const checkConnectionRequest = computed(
  () => props.topics["device_connection_status_request"]
);
const checkConnectionResponse = computed(
  () => props.topics["device_connection_status_response"]
);

const isOnline = ref(false);
const retryTimes = ref(0);
const client = ref();
const config = ref({
  baseUrl: import.meta.env.VITE_WSS_URL,
  clean: true,
  connectTimeout: 30 * 1000, // ms
  reconnectPeriod: 4000, // ms
  clientId: "sleepbytes_admin_" + Math.random().toString(16).substring(2, 8),
  username: "",
  password: localStorage.getItem("access_token"),
});

function handleOnReConnect() {
  retryTimes.value += 1;
  if (retryTimes.value > 5) {
    try {
      client.value.end();
      console.error("Connection maxReconnectTimes limit, stop retry");
    } catch (e) {
      console.error(e.message);
    }
  }
}

function connect() {
  console.log("Connecting to mqtt...");
  // console.log("Configs:", config.value);
  try {
    const { baseUrl, ...options } = config.value;
    client.value = mqtt.connect(baseUrl, options);
    if (client.value.on) {
      client.value.on("connect", async () => {
        console.log("Connection succeeded!");
        await subscribe(realtimeDataTopic.value, 0);
        await subscribe(deviceConnectionTopic.value, 0);
        await subscribe(viewRealtimeDataRequest.value, 0);
        await subscribe(viewRealtimeDataResponse.value, 0);
        await subscribe(checkConnectionRequest.value, 0);
        await subscribe(checkConnectionResponse.value, 0);
      });
      client.value.on("message", (topic, message) => {
        // console.log(`new message ${message} from ${topic}`);
        if (topic === realtimeDataTopic.value) {
          realtimeData.value = JSON.parse(`${message}`).data;
          data.value.push({
            x: realtimeData.value["eventTime"] * 1000,
            y: realtimeData.value[props.dataType],
          });
          if (data.value.length > 20) {
            data.value.shift();
          }
          // console.log(data.value);
          isOnline.value = true;
          // emits("statusUpdated", isOnline.value);
        }
        if (topic === deviceConnectionTopic.value) {
          const d = JSON.parse(`${message}`).data;
          isOnline.value = d.status === 1;
          emits("statusUpdated", d.status === 1);
          if (!isOnline.value) {
            realtimeData.value = {};
          } else {
            client.value.publish(viewRealtimeDataRequest.value, "");
          }
        }
        if (topic === checkConnectionResponse.value) {
          const d = JSON.parse(`${message}`).data;
          isOnline.value = d.status === 1;
          emits("statusUpdated", d.status === 1);
        }
      });
      client.value.on("error", (error) => {
        console.error("Connection failed", error);
        unsubscribe(realtimeDataTopic.value);
        unsubscribe(deviceConnectionTopic.value);
        unsubscribe(viewRealtimeDataRequest.value);
        unsubscribe(viewRealtimeDataResponse.value);
        unsubscribe(checkConnectionRequest.value);
        unsubscribe(checkConnectionResponse.value);
        client.value.end();
        isOnline.value = false;
        realtimeData.value = {};
        // emits("statusUpdated", isOnline.value);
      });
      client.value.on("reconnect", handleOnReConnect);
      client.value.on("end", () => {
        // console.log("Connection ended!");
        isOnline.value = false;
        // emits("statusUpdated", isOnline.value);
        realtimeData.value = {};
      });
      client.value.on("offline", () => {
        // console.log("Connection offline!");
        isOnline.value = false;
        // emits("statusUpdated", isOnline.value);
        realtimeData.value = {};
      });
    }
  } catch (e) {
    console.error("connectError:", e.message);
  }
}

async function subscribe(topic, qos) {
  return await client.value.subscribe(topic, { qos }, (error, res) => {
    if (error) {
      console.error("Subscribe to topics error", error);
      return;
    }
    if (
      topic === viewRealtimeDataRequest.value ||
      topic === checkConnectionRequest.value
    ) {
      client.value.publish(topic, "");
    }
  });
}

async function unsubscribe(topic) {
  await client.value.unsubscribe(topic, () => {});
}

onMounted(() => {
  connect();
});

onUnmounted(async () => {
  await unsubscribe(realtimeDataTopic.value);
  await unsubscribe(deviceConnectionTopic.value);
  await unsubscribe(viewRealtimeDataRequest.value);
  await unsubscribe(viewRealtimeDataResponse.value);
  await unsubscribe(checkConnectionRequest.value);
  await unsubscribe(checkConnectionResponse.value);
  client.value.end();
});
</script>

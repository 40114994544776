<template>
  <DataTable
      :lazy="true"
      :loading="isLoading"
      :value="reports?.data"
      stripedRows
      responsiveLayout="scroll"
      class="border rounded-md border-gray-200"
      ref="dt"
  >
    <!-- <Column field="id" header="ID"></Column> -->
    <Column field="user" :header="$t('user')"></Column>
    <Column field="device.name" :header="$t('device')">
      <template #body="{ data }">
        <div class="w-32 flex items-center gap-2">
          <span>{{ data.device.name ?? "-" }}</span>
          <router-link v-if="data.device?.id" :to="{ name: 'DeviceDetails', params: { id: data.device.id } }">
            <i class="pi pi-external-link text-sm"></i>
          </router-link>
        </div>
      </template>
    </Column>
    <Column field="startTime" :header="$t('start_time')">
      <template #body="{ data }">
        {{ useMoment(data.startTime).format("D MMM YYYY, HH:mm") }}
      </template>
    </Column
    >
    <Column field="sleep_time" :header="$t('fall_asleep')" class="font-bold">
      <template #body="{ data }">
        {{ mins2hrs(data.sleep_time) }}
      </template>
    </Column>
    <Column field="sleep_score" :header="$t('sleep_score')" class="text-center">
      <template #body="{ data }">
        <div class="w-32">
          <score-radia-bar :score="data.sleep_score"/>
        </div>
      </template>
    </Column>

    <Column>
      <template #body="{ data }">
        <button :label="$t('view_details')" class="rounded-lg bg-green-600 bg-opacity-25 text-green-700"
                @click="$router.push({ name: 'ReportDetails', params: { id: data.id } })">
          <pi class="pi pi-eye"></pi>
        </button>
      </template>
    </Column>

    <template #footer>
      <div class="sm:flex items-center justify-between">
        <span class="text-sm">
          {{ reports?.data?.length }} / {{ reports?.total }} {{ $t("report") }}
        </span>
        <Paginator
            :rows="reports?.per_page"
            :first="(reports?.current_page - 1) * reports?.per_page"
            :totalRecords="reports?.total"
            class="bg-transparent p-0"
            @page="$emit('onPage', $event)"
        >
        </Paginator>
      </div>
    </template>

    <template #empty>
      <div class="text-center p-5 font-semibold">
        No reports found on this range.
      </div>
    </template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import ScoreRadiaBar from "@/components/charts/ScoreRadiaBar.vue";
import moment from "moment";

export default {
  components: {
    DataTable,
    Column,
    Paginator,
    ScoreRadiaBar
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    reports: {
      type: Object,
      default: () => {
      },
    }
  },
  methods: {
    useMoment(timestamp) {
      return moment(timestamp * 1000);
    },

    mins2hrs(min) {
      let hrs = Math.floor(min / 60);
      let mins = min % 60;
      if (hrs == 0) {
        return ("0" + mins).slice(-2) + "min";
      }
      return ("0" + hrs).slice(-2) + "hrs " + ("0" + mins).slice(-2) + "min";
    },
  }
};
</script>
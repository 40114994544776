<template>
  <apexchart v-if="data" type="area" height="300" :options="chartOptions" :series="series" />
</template>

<script>
import locales from "@/locales/apexcharts";
export default {
  props: ["data", "reversed"],
  data() {
    return {
      series: [
        {
          name: this.$t("sleep_stages", 0),
          data: this.data,
        },
      ],
      chartOptions: {
        chart: {
          zoom: { autoScaleYaxis: true },
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        stroke: { width: 3 },
        dataLabels: { enabled: false },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              fontFamily: this.$store.getters.isRTL
                ? "Almarai"
                : "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          tickAmount: 3,
          reversed: this.reversed ?? false,
          min: 0,
          max: 3,
          labels: {
            offsetX: this.$store.getters.isRTL ? -35 : 0,
            formatter: (v) =>
              v == 0
                ? this.$t("slp_stage_awake")
                : v <= 1
                  ? this.$t("slp_stage_light")
                  : v <= 2
                    ? this.$t("slp_stage_medium")
                    : this.$t("slp_stage_deep"),
            style: {
              fontFamily: this.$store.getters.isRTL
                ? "Almarai"
                : "Helvetica, Arial, sans-serif",
            },
          },
        },
        tooltip: {
          x: { format: "HH:mm" },
        },
        colors: ["#B9412F"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
      },
    };
  },
};
</script>
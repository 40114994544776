export default {
    namespaced: true,
    state: {
        onlyHasDevice: true,
    },
    mutations: {
        toggleOnlyHasDevice(state) {
            state.onlyHasDevice = !state.onlyHasDevice
        }
    }
}
<template>
  <!-- Loading Skeleton -->
  <div v-if="loading" class="grid lg:grid-cols-3 gap-3 animate-pulse">
    <div class="h-64 rounded-md bg-gray-100"></div>
    <div class="h-64 rounded-md bg-gray-100"></div>
    <div class="h-64 rounded-md bg-gray-100"></div>
    <div class="col-span-3 h-64 rounded-md bg-gray-100"></div>
    <div class="grid grid-cols-2 col-span-3 gap-3">
      <div class="h-64 rounded-md bg-gray-100"></div>
      <div class="h-64 rounded-md bg-gray-100"></div>
    </div>
    <div class="h-64 rounded-md bg-gray-100"></div>
    <div class="h-64 rounded-md bg-gray-100"></div>
    <div class="h-64 rounded-md bg-gray-100"></div>
  </div>

  <!-- If data loaded -->
  <div v-else-if="data" class="grid lg:grid-cols-3 gap-3">
    <div class="grid sm:flex gap-3" :class="
      this.data.data.analysis.sleepCurveArray
        ? 'lg:col-span-2'
        : 'lg:col-span-3'
    ">
      <div class="m-card flex flex-col items-center justify-between flex-1">
        <span class="font-semibold text-gray-600 capitalize">{{
          $t("user_information")
        }}</span>
        <Avatar size="xlarge" shape="circle" :label="data?.user.name[0]"
          class="bg-gray-500 text-white font-bold uppercase mb-1" />
        <span class="text-sm font-semibold">{{ data?.user.name }}</span>
        <span class="text-xs font-light">{{ data?.user.email }}</span>
        <div v-if="data?.sleep_report.device"
          class="bg-gray-800 text-gray-100 px-3 rounded-full text-sm font-semibold flex gap-1">
          <span>{{ data?.sleep_report.device?.name ?? "-" }}</span>
          <router-link v-if="data?.sleep_report.device?.id"
            :to="{ name: 'DeviceDetails', params: { id: data?.sleep_report.device.id } }">
            <i class="pi pi-external-link text-xs font-bold"></i>
          </router-link>
        </div>
        <Badge class="rounded mt-2" :value="
          data?.user.account_type == 0
            ? 'Email'
            : data?.user.account_type == 1
              ? 'Facebook'
              : data?.user.account_type == 2
                ? 'Google'
                : data?.user.account_type == 3
                  ? 'Apple'
                  : ''
        " :class="{
  'bg-green-200 text-green-700': data.user.account_type == 0,
  'bg-blue-200 text-blue-700': data.user.account_type == 1,
  'bg-red-200 text-red-700': data.user.account_type == 2,
  'bg-gray-300 text-gray-700': data.user.account_type == 3,
}" />
      </div>
      <div class="m-card flex flex-col items-center justify-between flex-1">
        <span class="font-semibold text-gray-600 capitalize">{{
          $t("sleep_score")
        }}</span>
        <ScoreRadiaBar class="" :score="data.data.analysis.sleepScore" />
        <span class="font-bold text-lg text-gray-800">
          {{ sleepScore(data.data.analysis.sleepScore) }}
        </span>
      </div>
      <div class="m-card flex flex-col items-center justify-between flex-1">
        <span class="font-semibold text-gray-600 capitalize">{{
          $t("fall_asleep")
        }}</span>
        <div class="grid text-center my-1.5">
          <span class="text-4xl font-extrabold">
            {{ mins2hrs(data.data.summary.recordCount) }}
          </span>
          <span class="text-sm font-medium text-gray-500">
            <i class="bi bi-clock"></i>
            {{ useMoment(startTime()).format("HH:mm") }} -
            {{ useMoment(endTime()).format("HH:mm") }}
          </span>
        </div>
        <div class="
                flex
                items-center
                gap-3
                text-xl
                leading-none
                font-extrabold
                text-gray-700
              ">
          <div class="grid text-center">
            <span>{{ useMoment(startTime()).format("D") }}</span>
            <span>{{ useMoment(startTime()).format("MMM") }}</span>
            <span>{{ useMoment(startTime()).format("YYYY") }}</span>
          </div>
          -
          <div class="grid text-center">
            <span>{{ useMoment(endTime()).format("D") }}</span>
            <span>{{ useMoment(endTime()).format("MMM") }}</span>
            <span>{{ useMoment(endTime()).format("YYYY") }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="m-card" v-if="this.data.data.analysis.sleepCurveArray">
      <span class="font-semibold text-gray-600 capitalize">{{
        $t("sleep_stages")
      }}</span>
      <TreeMapChart class="capitalize" :data="[
        { x: $t('slp_stage_deep'), y: data.data.analysis.deepSleepPerc },
        { x: $t('slp_stage_medium'), y: data.data.analysis.inSleepPerc },
        { x: $t('slp_stage_light'), y: data.data.analysis.lightSleepPerc },
        { x: $t('slp_stage_awake'), y: data.data.analysis.wakeSleepPerc },
      ]" />
    </div>

    <div class="m-card lg:col-span-3" v-if="this.data.data.analysis.sleepCurveArray">
      <span class="font-semibold text-gray-600 capitalize">{{
        $t("sleep_curve")
      }}</span>
      <SleepCurveChart class="capitalize" :data="createTimeLineData(this.data.data.analysis.sleepCurveArray)" />
    </div>

    <div class="m-card lg:col-span-3" v-if="this.data.data.analysis.sleepCurveArray">
      <span class="font-semibold text-gray-600 capitalize">{{
        $t("sleep_curve")
      }}</span>
      <SleepCurveChart class="capitalize" :data="createTimeLineData(this.data.data.analysis.sleepCurveArray)"
        :reversed="true" />
    </div>

    <div class="lg:col-span-3 grid lg:grid-cols-2 gap-3">
      <div class="m-card">
        <span class="font-semibold text-gray-600 capitalize">{{ $t("heart_rate") }}</span>
        <TimeLineChart :data="createTimeLineData(this.data.data.detail.heartRate)" :max="200" />
      </div>

      <div class="m-card">
        <span class="font-semibold text-gray-600 capitalize">{{ $t("breath_rate") }}</span>
        <TimeLineChart :data="createTimeLineData(this.data.data.detail.breathRate)" :max="40" />
      </div>
    </div>

    <div class="m-card">
      <span class="font-semibold text-gray-600 capitalize">{{ $t("humidity") }}</span>
      <TimeLineChart :data="createTimeLineData(this.data.data.detail.eHumidity)" :max="100" />
    </div>

    <div class="m-card">
      <span class="font-semibold text-gray-600 capitalize">{{ $t("temperature") }}</span>
      <TimeLineChart :data="createTimeLineData(this.data.data.detail.eTemp)" :max="70" />
    </div>

    <div class="m-card">
      <span class="font-semibold text-gray-600 capitalize">{{ $t("turn_over") }}</span>
      <TimeLineChart :data="createTimeLineData(this.data.data.analysis.turnOverStatusAry)" :max="5" />
    </div>
  </div>
</template>

<script>
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import axios from "axios";
import moment from "moment";
import ScoreRadiaBar from "../../../components/charts/ScoreRadiaBar.vue";
import TreeMapChart from "../../../components/charts/TreeMapChart.vue";
import SleepCurveChart from "../../../components/charts/SleepCurveChart.vue";
import TimeLineChart from "../../../components/charts/TimeLineChart.vue";
export default {
  props: ["id"],
  components: {
    Avatar,
    Badge,
    ScoreRadiaBar,
    TreeMapChart,
    TimeLineChart,
    SleepCurveChart,
  },
  data() {
    return {
      loading: true,
      data: null,
    };
  },
  methods: {
    sleepScore(qualityScore) {
      if (qualityScore == 0) return "";
      return qualityScore < 60
        ? this.$t('bad')
        : qualityScore < 80
          ? this.$t('average')
          : qualityScore < 90
            ? this.$t('good')
            : this.$t('bravo');
    },
    mins2hrs(min) {
      let hrs = Math.floor(min / 60);
      let mins = min % 60;
      return ("0" + hrs).slice(-2) + "h" + mins + "min";
    },
    startTime() {
      return new Date(this.data.data.summary.startTime * 1000);
    },
    endTime() {
      return new Date(
        (this.data.data.summary.recordCount * 60 +
          this.data.data.summary.startTime) *
        1000
      );
    },
    useMoment(date) {
      return moment(date);
    },
    createTimeLineData(array) {
      let me = this;
      return Array.from(
        { length: me.data.data.summary.recordCount },
        (x, i) => [
          1000 * (me.data.data.summary.startTime + me.data.data.summary.timezone + i * 60),
          Math.max(0, array[i]),
        ]
      );
    },
  },
  async mounted() {
    this.loading = true;
    let response = null;

    await axios
      .get("sleepreports/" + this.id)
      .then((res) => (response = res))
      .catch((err) => (response = err.response));

    if (response?.status == 200) {
      this.data = response.data;
    } else if (response?.status == 404) {
      this.$router.replace({ name: "404" });
    }
    this.loading = false;
  },
};
</script>
<template>
  <div class="card border-b-4 relative h-full">
    <div class="grid h-full">
      <span class="font-semibold text-sm capitalize absolute">{{ title }}</span>
      <div class="flex pt-5 items-center">
        <span class="font-extrabold text-3xl">
          {{ count }}
        </span>
        <sub v-if="total" class="pt-3 font-bold" style="font-size: 0.5rem">
          <span class="mx-1">/</span>{{ total }}
        </sub>
      </div>
      <span
        v-if="todayCount != undefined"
        class="font-semibold opacity-70 text-xs"
      >
        +{{ todayCount }} {{ $t("today") }}
      </span>
    </div>
    <div class="w-40 h-20">
      <line-chart-without-axis
        v-if="series"
        :series="series"
        :labels="labels"
        :color="chartColor"
      />
    </div>
  </div>
  <!-- <div v-else class="bg-gray-200 rounded-md h-32 animate-pulse"></div> -->
</template>

<script>
import LineChartWithoutAxis from "../charts/LineChartWithoutAxis.vue";
export default {
  components: { LineChartWithoutAxis },
  props: [
    "series",
    "labels",
    "title",
    "count",
    "todayCount",
    "chartColor",
    "total",
  ],
};
</script>

<style scoped>
.card {
  @apply p-3 rounded bg-opacity-50 flex items-center 
  justify-between overflow-hidden;
}
</style>
import axios from "axios"

export default {
    namespaced: true,
    state: {
        type: null,
        status: null,
        onlineDevices: [],
        onlyOnline: false,
        onlyHasReports: false,
    },
    getters: {

    },
    mutations: {
        setType(state, type) {
            state.type = type
        },
        setStatus(state, status) {
            state.status = status
        },
    }
}
<template>
  <span
    class="cursor-pointer relative inline-block"
    @click="$refs.messages.toggle"
  >
    <svg class="w-6 h-6 text-gray-700 fill-current" viewBox="0 0 20 20">
      <path
        d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
    </svg>
    <span v-if="newCount > 0" class="badge">
      {{ newCount > 9 ? "9+" : newCount }}
    </span>
  </span>
  <OverlayPanel
    ref="messages"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
    class="shadow-lg"
  >
    <div class="grid relative">
      <div class="py-3 px-4">
        <h2 class="text-xl font-medium capitalize">
          {{ $t("message", 2) }}
        </h2>
      </div>
      <!-- Message Item -->
      <div class="h-96 overflow-y-scroll">
        <div
          v-if="$store.state.messages.latest.length > 0"
          v-for="item in $store.state.messages.latest"
          :key="item.id"
          @click="selectItem(item)"
          class="
            flex
            items-start
            justify-between
            cursor-pointer
            hover:bg-gray-100
            p-2
            rounded-md
            m-1.5
          "
        >
          <div class="flex items-center gap-2">
            <div class="items-center">
              <div class="avatar-text">
                {{ item.sender_name[0] }}
              </div>
            </div>
            <div class="grid leading-none">
              <div class="font-medium mb-0.5">{{ item.sender_name }}</div>
              <span
                class="text-gray-500 text-xs truncate w-11/12"
                :class="{ 'font-bold': item.is_new }"
              >
                {{ item.message }}
              </span>
              <span class="text-gray-600 mt-1" style="font-size: 0.6rem">
                <i class="pi pi-clock" style="font-size: 0.5rem"></i>
                {{ useMoment(item.created_at).fromNow() }}
              </span>
            </div>
          </div>
          <div v-if="item.is_new" class="bg-blue-500 rounded-full p-1"></div>
        </div>
        <div
          class="grid justify-center items-center h-full"
          v-else
        >
          <div class="flex flex-col items-center">
            <i class="pi pi-comments" style="font-size: 3rem"></i>
            <strong class="m-2">No messages available</strong>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="border-t p-2 text-center">
        <router-link @click="$refs.messages.hide" :to="{ name: 'Messages' }">
          {{ $t("all_messages") }}
        </router-link>
      </div>
    </div>
  </OverlayPanel>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import Avatar from "primevue/avatar";
import moment from "moment";
export default {
  components: {
    OverlayPanel,
    Avatar,
  },
  data() {
    return {
      interval: null,
      list: [1, 2, 3],
    };
  },
  computed: {
    newCount() {
      return this.$store.state.messages.newCount;
    },
  },
  methods: {
    useMoment(d) {
      return moment(d);
    },
    selectItem(item) {
      this.$store.state.messages.selectedItem = item;
      this.$refs.messages.hide();
      this.$router.push({ name: "Messages" });
    },
  },
  mounted() {
    this.$store.dispatch("messages/getLatest");
    this.interval = setInterval(() => {
      this.$store.dispatch("messages/getLatest");
    }, 60 * 1000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.badge {
  @apply absolute
            top-0
            right-0
            inline-flex
            items-center
            justify-center
            px-2
            py-1
            text-xs
            font-bold
            leading-none
            text-red-100
            transform
            translate-x-1/2
            -translate-y-1/2
            bg-red-600
            rounded-full;
}

.avatar-text {
  @apply grid
            place-content-center
            rounded-full
            w-12
            h-12
            bg-gray-300
            text-gray-600
            capitalize
            font-medium
            text-xl;
}
</style>
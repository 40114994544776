<template>
  <DataTable :lazy="true" :loading="isLoading" :value="devices?.data" stripedRows responsiveLayout="scroll"
             class="border rounded-md border-gray-200" ref="dt">
    <Column :header="$t('device_type')" class="text-sm font-bold">
      <template #body="{ data }">
        {{ data.name.startsWith("M800") ? "Sleep Monitor" : "Aroma" }}
      </template>
    </Column>

    <Column field="name" :header="$t('name')"></Column>

    <Column :header="$t('report')">
      <template #body="{ data }">
        <div class="flex gap-2">
          <span v-if="data.name.startsWith('M800')" class="font-bold">{{
              data.sleepreports_count + " " + $t("report")
            }}</span>
          <span v-else>-</span>
          <router-link v-if="data.sleepreports_count > 0"
                       :to="{ name: 'DeviceReports', params: { device_id: data.id } }">
            <i class="pi pi-external-link text-xs"></i>
          </router-link>
        </div>
      </template>
    </Column>

    <Column field="is_bound" :header="$t('status')">
      <template #body="{ data }">
        <badge class="rounded capitalize" :value="data.is_bound ? $t('bound') : $t('not_bound')" :class="
          data.is_bound
            ? 'bg-green-200 text-green-700'
            : 'bg-gray-300 text-gray-700'
        "/>
      </template>
    </Column>

    <Column :header="$t('user')">
      <template #body="{ data }">
        <div v-if="data.is_bound" class="d-flex gap-1" v-for="usr in data.userdevices" :key="usr['id']">
          <span class="text-sm">{{ usr.user.name }}</span>
          <router-link :to="{ name: 'UserDetails', params: { id: usr.user_id } }">
            <i class="pi pi-external-link text-sm mx-0.5"></i>
          </router-link>
        </div>
        <div class="" v-else> -</div>
      </template>
    </Column>

    <Column class="text-left">
      <template #body="{ data }">
        <button v-if="data.is_bound" :label="$t('view_details')" class="bg-green-600 bg-opacity-25 text-green-700"
                @click="$router.push({ name: 'DeviceDetails', params: { id: data.id } })">
          <pi class="pi pi-eye"></pi>
        </button>
        <div v-else class="flex gap-2 p-3 items-center border bg-gray-100 rounded-lg w-fit">
          <i class="pi pi-info-circle font-bold text-gray-600"></i>
          <span class="font-bold text-gray-600">{{ $t("no_details_available") }}</span>
        </div>
      </template>
    </Column>

    <template #footer>
      <div class="sm:flex items-center justify-between">
        <span class="text-sm">
          <!--          {{-->
          <!--            $t("pagination_footer_message", {-->
          <!--              a: devices?.data?.length,-->
          <!--              b: devices?.total,-->
          <!--              records: $t("device", devices?.total),-->
          <!--            })-->
          <!--          }}-->
          {{ devices?.data?.length }} / {{ devices?.total }} {{ $t("device") }}
        </span>
        <Paginator :rows="devices?.per_page" :totalRecords="devices?.total"
                   :first="(devices?.current_page - 1) * devices?.per_page" class="bg-transparent p-0"
                   @page="$emit('onPage', $event)">
        </Paginator>
      </div>
    </template>

    <template #empty>
      <div class="text-center p-5 font-semibold">
        {{ $t("no_devices") }}
      </div>
    </template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from "primevue/badge";
import Paginator from "primevue/paginator";

export default {
  components: {
    DataTable,
    Column,
    Badge,
    Paginator,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Object,
      default: () => {
      },
    }
  },
};
</script>
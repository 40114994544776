export default {
  lang: "ar",
  android: "أندرويد",
  bound: "مستعمل",
  chart_bound_devices: "الأجهزة المستعملة",
  bound_devices: "الأجهزة المستعملة",
  chart_customers_by_phone_os: "الزبناء حسب نظام الهاتف",
  chart_cutomers_by_city: "الزبناء حسب المدينة",
  chart_registred_users_by_day: "عدد المستخدمين الجدد في اليوم",
  customer: "زبناء",
  dashboard: "لوحة التحكم",
  device: "الأجهزة",
  inbox: "علبة الرسائل",
  ios: "آيفون",
  message: "الرسائل",
  report: "تقارير",
  today: "اليوم",
  total: "المجموع",
  user: "المستخدمين",
  logout_confirm_message: "هل أنت متأكد من ستجيل الخروج؟",
  no: "لا",
  yes: "نعم",
  placeholder_users_search: "البحث عن طريق الإسم او البريد الإلكتروني أو اسم الجهاز",
  checkbox_users_with_devices: "الزبناء فقط",
  email: "البريد الإلكتروني",
  account_type: "نوع الحساب",
  status: "الحالة",
  registration_date: "تاريخ التسجيل",
  account_status: "حالة الحساب",
  verified: "مفعل",
  non_verified: "غير مفعل",
  admin: "المدير",
  support: "المشرف",
  pagination_footer_message: "إظهار {a} من {b} {records}",
  role: "المرتبة",
  device_type: "نوع الجهاز",
  name: "الإسم",
  device_status: "حالة الجهاز",
  not_bound: "غير مستعمل",
  search: "البحث",
  score_range: "مجال التقييم",
  sleep_score: "تقييم النوم",
  fall_asleep: "النوم",
  start_time: "وقت النوم",
  export: "استخراج",
  placeholder_messages_search_input: "بحث",
  refresh: "تحديث",
  pending: "معلق",
  resolved: "تم الحل",
  unresolved: "لم يتم الحل",
  trash: "سلة المهملات",
  priority: "أولوية",
  high: "عالية",
  medium: "متوسطة",
  low: "منخفضة",
  move_to_trash: "إزالة",
  more: "المزيد",
  make_as_high: "أولوية عالية",
  make_as_medium: "أولوية متوسطة",
  make_as_low: "أولوية منخفضة",
  restore: "استعادة",
  restore_all: "استعادةالكل",
  delete_permanently: "حذف نهائيًا",
  empty_trash: "تفريغ سلة المهملات",
  no_devices: "لم يتم العثور على أجهزة.",
  no_reports: "لم يتم العثور على تقارير.",
  side: "جانب",
  type: "نوع",
  last: "آخر",
  left: "يسار",
  right: "يمين",
  see_all: "الكل",
  no_device_details: "لا توجد معلومات حول هذا الجهاز",
  device_monitoring: "مراقبة الجهاز",
  sleep_stages: "مراحل النوم",
  slp_stage_deep: "عميق",
  slp_stage_medium: "متوسط",
  slp_stage_light: "خفيف",
  slp_stage_awake: "مستيقظ",
  sleep_curve: "منحنى النوم",
  heart_rate: "معدل ضربات القلب",
  breath_rate: "معدل التنفس",
  humidity: "الرطوبة",
  temperature: "درجة الحرارة",
  turn_over: "التقلب في النوم",
  bad: "ضعيف",
  average: "متوسط",
  good: "جيد",
  bravo: "ممتاز",
  user_information: "معلومات المستخدم",
  is_online: "حالة الإتصال",
  online: "متصل",
  offline: "غير متصل",
  advanced_filters: "بحث متقدم",
  clear_filters: "إعادة ضبط",
  checkbox_only_online: "الأجهزة المتصلة",
  online_devices: "الأجهزة المتصلة",
  all_messages: "جميع الرسائل",
  notify: "إرسال إشعار",
  search_with_device_name: "البحث عن طريق إسم الجهاز",
  change_password: "تغيير كلمة المرور",
  change_your_account_password: "تغيير كلمة المرور لحسابك",
  password_changed: "تم تغيير كلمة المرور بنجاح",
  current_password: "كلمة المرور الحالية",
  new_password: "كلمة المرور الجديدة",
  confirm_password: "تأكيد كلمة المرور",
  passwords_not_match: "كلمتا المرور غير متطابقتان",
  saving: "جاري الحفظ...",
  incorrect_password: "كلمة المرور غير صحيحة",
  settings: "الإعدادات",
  showing: "إظهار",
  from: "من",
  checkbox_device_has_reports: "مع التقارير",
  view_details: "معاينة التفاصيل",
  mattress: "سرير",
  sleepaid: "Sleepaid",
  number_of_nights: "عدد الليالي",
  number_of_laids: "عدد الرقدات",
  no_details_available: "لا توجد تفاصيل",
  heartRate: "دقات القلب",
  breathRate: "التنفس",
  other: "آخر",
  data_deletion: "حذب البيانات",
  account_deletion: "حذف الحساب",
  no_user_information: "لا توجد بيانات لهذا المستخدم",
  device_information: "معلومات الجهاز",
  contact_information: "معلومات الاتصال",
  change_your_contact_information: "تغيير معلومات الاتصال الخاصة بك",
  edit: "تحرير",
  save: "حفظ",
  contact_email: "البريد الإلكتروني للاتصال",
  phone_number: "رقم الهاتف",
  support_email: "البريد الإلكتروني للدعم",
  push: "إرسال",
  users: "المستخدمين",
  notification: "تنبيه",
  notification_title: "عنوان التنبيه",
  notification_message: "رسالة التنبيه",
  all_filtred_users: "جميع المستخدمين المُنقَّحين",
  all_filtred_users_except: "جميع المستخدمين المُنقحين، باستثناء",
  only_selected_users: "المستخدمين المحددين فقط",
  select_users: "اختر المستخدمين",
  cancel: "إلغاء",
  send: "إرسال",
  your_password: "كلمة المرور الخاصة بك",
  notification_dialog_title: "تأكيد الإشعار",
  notification_dialog_message1: "قبل المتابعة، يرجى تأكيد إجراءك عن طريق إدخال كلمة المرور الخاصة بك:",
  notification_dialog_message2: "من خلال التأكيد، تقوم بتفويض النظام لإرسال إشعارات إلى المستخدمين المحددين. هذا الإجراء لا يمكن التراجع عنه.",
  "The password is incorrect.": "كلمة المرور غير صحيحة.",
  notification_sent_summary: "تم إرسال الإشعار إلى المستخدمين المحددين.",
  notification_sent_detail: "تم إرسال الإشعار",
  notification_failed_summary: "فشل الإشعار",
  notification_failed_detail: "فشل إرسال الإشعار إلى المستخدمين",
  notification_body_hint: "لإضافة اسم المستخدم داخل هيكل النص، قم ببساطة بإدراج الوسم '{name}' في محتواك.",
  help_url_ar: "رابط صفحة المساعدة (AR)",
  help_url_en: "رابط صفحة المساعدة (EN)",
  help_url_fa: "رابط صفحة المساعدة (FA)",
  help_url_fr: "رابط صفحة المساعدة (FR)",
  privacy_policy_url_ar: "رابط سياسة الخصوصية (AR)",
  privacy_policy_url_en: "رابط سياسة الخصوصية (EN)",
  privacy_policy_url_fa: "رابط سياسة الخصوصية (FA)",
  privacy_policy_url_fr: "رابط سياسة الخصوصية (FR)",
  advice: "نصائح"
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        types: ['adv', 'rel_info', 'fun'],
        adviceList: {},
        selectedAdvice: null,
        loadingList: false,
        errorMessage: ''
    },
    actions: {
        index: async ({state}, payload) => {
            state.errorMessage = ''
            state.loadingList = true
            try {
                const response = await axios.get("/advice", {params: payload})
                state.adviceList = response.data
            } catch (e) {
                state.errorMessage = e.response.data.message
            } finally {
                state.loadingList = false
            }
        },
        show: async ({state}, id) => {
            state.errorMessage = ''
            try {
                const response = await axios.get(`/advice/${id}`)
                state.selectedAdvice = response.data
            } catch (e) {
                state.errorMessage = e.response.data.message
            } finally {
            }
        },
        remove: async ({state}, id) => {
            state.errorMessage = ''
            try {
                await axios.delete(`/advice/${id}`)
                return true
            } catch (e) {
                state.errorMessage = e.response.data.message
                return false
            }
        },
        store: async ({state}, payload) => {
            state.errorMessage = ''
            try {
                await axios.post(`/advice`, payload)
                return true
            } catch (e) {
                state.errorMessage = e.response.data.message
                return false
            }
        },
        update: async ({state}, payload) => {
            state.errorMessage = ''
            try {
                await axios.put(`/advice/${payload.id}`, payload)
                return true
            } catch (e) {
                state.errorMessage = e.response.data.message
                return false
            }
        }
    }
}
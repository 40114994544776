<template>
  <div v-if="currentUser" class="alert alert-info" :class="{ 'flex-row-reverse ': $store.getters.isRTL }">
    <i class="pi pi-info-circle ml-1 mr-2"></i>
    This reports for
    <strong class="mx-1"> {{ currentUser.name }} </strong>
    <span>({{ currentUser.email }})</span>
    <router-link :to="{ name: 'UserDetails', params: { id: currentUser.id } }">
      <i class="pi pi-external-link text-xs px-2"></i>
    </router-link>
  </div>

  <div class="border rounded-md border-gray-300 shadow-sm mb-3 p-5">
    <div class="flex gap-3 mb-4 justify-between justify-items-center">
      <div class="text-2xl text-gray-700">{{ $t("advanced_filters") }}</div>
      <button class="py-1.5 px-3 rounded border border-gray-900 font-semibold" @click="resetFilters">
        <span class="text-sm">{{ $t("clear_filters") }}</span>
      </button>
    </div>
    <div class="flex flex-wrap gap-5">
      <div class="grid w-60">
        <label for="from_date">From</label>
        <input id="from_date" type="date" class="rounded border px-3 py-2" :max="lastDate" v-model="firstDate"
          @change="filter()" />
      </div>
      <div class="grid w-60">
        <label for="to_date">To</label>
        <input id="to_date" type="date" class="rounded border px-3 py-2" :max="now" v-model="lastDate"
          @change="filter()" />
      </div>
      <div class="grid gap-1 w-96 mx-5">
        <label for="" class="capitalize">
          {{ $t("score_range") }}: {{ $store.state.reports.scoreRange[0] }} -
          {{ $store.state.reports.scoreRange[1] }}
        </label>
        <Slider v-model="$store.state.reports.scoreRange" :range="true" @slideend="filter()" />
      </div>
    </div>
  </div>

  <div class="
          flex
          gap-2
          justify-items-stretch
          items-center
          justify-between
          mt-5
          py-3
        ">
    <span v-if="userId == undefined" class="flex items-center w-full border rounded-lg shadow-sm">
      <i class="pi pi-search ps-4"></i>
      <input class="outline-none w-full" type="text" :placeholder="$t('placeholder_users_search')" v-model="search"
        @change="filter()" />
    </span>

    <Button class="p-button-sm p-button-success capitalize flex-grow-0 flex-shrink-0" icon="pi pi-external-link"
      :label="$t('export')" @click="exportCSV($event)" ></Button>
  </div>
  <div class="p-4 rounded border mb-3 gap-5 flex place-content-center">
    <div class="grid justify-items-center gap-1">
      <span class="text-lg font-semibold text-gray-400">{{ $t("number_of_nights") }}</span>
      <span class="text-3xl font-black text-gray-700">{{ numberOfNights }}</span>
    </div>
    <div class="grid justify-items-center gap-1">
      <span class="text-lg font-semibold text-gray-400">{{ $t("number_of_laids") }}</span>
      <span class="text-3xl font-black text-gray-700">{{ numberOfLaids }}</span>
    </div>
  </div>
  <ReportsDataTable :isLoading="loading" :reports="reports" @onPage="onPage($event)" />
</template>

<script>
import Slider from "primevue/slider";
import axios from "axios";
import moment from "moment";

import ReportsDataTable from "@/components/reports/ReportsDataTable.vue";

export default {
  components: {
    Slider,
    ReportsDataTable,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
    deviceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: null,
      currentUser: null,
      search: this.$route.query.search ?? "", //filter
      page: Number(this.$route.query.page ?? 1),
      totalCount: null,
      perPage: null,
      reports: null,
      countPage: null,
      firstDate: moment().subtract(3, "years").format("YYYY-MM-DD"),
      lastDate: moment().format("YYYY-MM-DD"),
      now: moment().format("YYYY-MM-DD"),
      numberOfNights: 0,
      numberOfLaids: 0,
    };
  },
  methods: {
    onPage(event) {
      this.page = event.page + 1;
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, page: event.page + 1 },
      });
      this.fetch();
    },

    exportCSV(event) {
      this.$refs.dt.exportCSV();
    },

    async fetch() {
      // let dateRange = this.$store.state.reports.dateRange;
      let dateRange = [this.firstDate, this.lastDate];

      this.loading = true;
      let response = null;
      let url =
        "sleepreports?page=" +
        this.page +
        "&search=" +
        this.search +
        "&maxScore=" +
        this.$store.state.reports.scoreRange[1] +
        "&minScore=" +
        this.$store.state.reports.scoreRange[0];

      if (this.userId) {
        url += "&userId=" + this.userId;
      }

      if (this.deviceId) {
        url += "&deviceId=" + this.deviceId;
      }

      if (dateRange) {
        let times = [
          Math.floor(new Date(dateRange[0]).getTime() / 1000),
          Math.floor(
            new Date(
              moment(dateRange[1] ?? dateRange[0]).subtract(-1, "days")
            ).getTime() / 1000
          ),
        ];
        url += "&startTime=" + times[0] + "&endTime=" + times[1];
      }

      await axios
        .get(url)
        .then((res) => (response = res))
        .catch((err) => (response = err.response));

      if (response.status == 200) {
        this.page = response.data.reports.current_page;
        this.totalCount = response.data.reports.total;
        this.perPage = response.data.reports.per_page;
        this.reports = response.data.reports;
        this.countPage = response.data.reports.to;
        this.numberOfNights = response.data.nights_count
        this.numberOfLaids = response.data.laids_count
      }
      this.loading = false;
    },

    filter() {
      this.page = 1;
      this.fetch();
    },
  },

  async mounted() {
    if (this.userId) {
      this.loading = true;
      this.$store.state.reports.dateRange = null;
      await axios
        .get("users/" + this.userId)
        .then((response) => {
          this.currentUser = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
    await this.fetch();
  },
};
</script>

<style>
.p-slider .p-slider-range {
  background: #2865d5;
}

.p-slider .p-slider-handle {
  border-color: #2865d5;
}

.alert {
  @apply w-full bg-gray-400 text-gray-700 bg-opacity-25 p-2 rounded-md mb-2 flex items-center;
}

.alert-info {
  @apply bg-blue-400 text-blue-700 bg-opacity-25;
}
</style>
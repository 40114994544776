<template>
  <div class="grid">
    <span class="text-left px-4 pt-2 text-sm font-semibold capitalize">
      {{ $t("chart_bound_devices") }}
    </span>
    <apexchart
      v-if="series"
      class="px-3"
      type="bar"
      :options="chartOptions"
      :series="series"
      height="350"
    ></apexchart>
  </div>
</template>

<script>
import locales from "@/locales/apexcharts";
export default {
  props: ["data"],
  data() {
    return {
      series: this.data.series,
      chartOptions: {
        labels: this.data.labels.map((v,k) => this.$t(v)),
        // title: { text: this.$t("chart_bound_devices") },
        legend: {
          position: "bottom",
          markers: { offsetX: this.$store.getters.isRTL ? 5 : 0 },
          itemMargin: { horizontal: 10 },
        },
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontFamily: this.$store.getters.isRTL ? "Almarai, sans-serif" : "",
          },
        },
      },
    };
  },
  mounted() {
    // console.log(this.data);
  },
};
</script>
<template>
  <div class="grid capitalize">
    <span class="text-left px-4 pt-2 text-sm font-semibold">
      {{ $t("chart_customers_by_phone_os") }}
    </span>
    <apexchart
      v-if="series"
      type="donut"
      :options="chartOptions"
      :series="series"
      height="350"
    ></apexchart>
  </div>
  <!-- <div v-else class="animate-pulse h-80 bg-gray-200"></div> -->
</template>

<script>
import locales from "@/locales/apexcharts";
import axios from "axios";
export default {
  props: ["series", "labels"],
  data() {
    return {
      // series: this.series,
      chartOptions: {
        labels: this.labels, //["iOS", "Android"],
        // title: { text: "Customers by phone OS" },
        legend: {
          position: "bottom",
          markers: { offsetX: this.$store.getters.isRTL ? 5 : 0 },
          itemMargin: { horizontal: 10 },
        },
        colors: ["#3DD382", "#546E7A"],
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
              labels: {
                show: true,
                total: {
                  show: true,
                  color: "#373d3f",
                  label: this.$t("total"),
                  fontWeight: 600,
                  fontFamily: this.$store.getters.isRTL
                    ? "Almarai, sans-serif"
                    : "",
                },
              },
            },
          },
        },
        tooltip: {
          style: {
            fontFamily: "Almarai, sans-serif",
          },
        },
      },
    };
  },
  mounted() {
    // axios
    //   .get("dashboard/customers-by-phone")
    //   .then((res) => {
    //     console.log(res.data.data.map(e=>Number(e)))
    //     this.series = res.data.data.map(e=>Number(e))
    //     this.chartOptions.labels = res.data.labels.map((item) => item == '1' ? 'Android' : 'iOS')
    //   })
    //   .catch((err) => console.error(err));
  },
};
</script>

<style lang="">
</style>
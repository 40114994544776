export default {
  lang: "fr",
  android: "Android",
  bound: "lié",
  chart_bound_devices: "appareils liés",
  bound_devices: "appareils liés",
  chart_customers_by_phone_os: "clients par téléphone OS",
  chart_cutomers_by_city: "clients par ville",
  chart_registred_users_by_day: "utilisateurs enregistrés par jour",
  customer: "clients",
  dashboard: "tableau de bord",
  device: "appareils",
  inbox: "boîte",
  ios: "iOS",
  message: "messages",
  report: "rapports",
  today: "aujourd'hui",
  total: "total",
  user: "utilisateurs",
  logout_confirm_message: "êtes-vous sûr de vouloir vous déconnecter ?",
  no: "Non",
  yes: "Oui",
  placeholder_users_search: "Recherche par nom, e-mail ou le nom de l'appariel",
  checkbox_users_with_devices: "utilisateurs avec appareils",
  email: "email",
  account_type: "type de compte",
  status: "état",
  registration_date: "enregistré",
  account_status: "état du compte",
  verified: "vérifié",
  non_verified: "non vérifié",
  admin: "admin",
  support: "support",
  pagination_footer_message: "Afficher {a} de {b} {records}",
  role: "rôle",
  device_type: "Type de l'appareil",
  name: "nom",
  device_status: "Status de l'appareil",
  not_bound: "non lié",
  search: "recherche",
  score_range: "plage de score",
  sleep_score: "score de sommeil",
  fall_asleep: "s'endormir",
  start_time: "temps de sommeil",
  export: "exporter",
  placeholder_messages_search_input: "recherche - mot-clé",
  refresh: "actualiser",
  pending: "en attente",
  resolved: "résolu",
  unresolved: "non résolu",
  trash: "corbeille",
  priority: "priorité",
  high: "élevé",
  medium: "moyen",
  low: "faible",
  move_to_trash: "supprimer",
  more: "plus",
  make_as_high: "priorité élevé",
  make_as_medium: "priorité moyen",
  make_as_low: "priorité faible",
  restore: "Restaurer",
  restore_all: "Restaurer tous",
  delete_permanently: "Supprimer définitivement",
  empty_trash: "Vider la corbeille",
  no_devices: "Aucun appareil trouvé.",
  no_reports: "Aucun rapport trouvé.",
  side: "côté",
  type: "type",
  last: "dernier",
  left: "gauche",
  right: "droite",
  see_all: "Tous",
  no_device_details: "Pas d'information apropos de cette appreil.",
  device_monitoring: "Surveillance de l'appareil",
  sleep_stages: "phases de sommeil",
  slp_stage_deep: "profond",
  slp_stage_medium: "moyen",
  slp_stage_light: "léger",
  slp_stage_awake: "éveillé",
  sleep_curve: "courbe de sommeil",
  heart_rate: "fréquence cardiaque",
  breath_rate: "fréquence respiratoire",
  humidity: "humidité",
  temperature: "température",
  turn_over: "retour",
  bad: "Mauvais",
  average: "Moyen",
  good: "Bon",
  bravo: "Bravo",
  user_information: "information de l'utilisateur",
  is_online: "en ligne status",
  online: "en ligne",
  offline: "hors ligne",
  advanced_filters: "Filtres Avancés",
  clear_filters: "Réinitialisation",
  checkbox_only_online: "Appareils en ligne",
  online_devices: "Appareils en ligne",
  all_messages: "Tous les messages",
  notify: "Notifier",
  search_with_device_name: "Recherche par nom d'appareil",
  change_password: "Changer le mot de passe",
  change_your_account_password: "Changer votre mot de passe",
  password_changed: "Mot de passe a été changé avec succès.",
  current_password: "Mot de passe actuel",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmer le mot de passe",
  passwords_not_match: "Les mots de passe ne correspondent pas.",
  saving: "Sauvegarde en cours...",
  incorrect_password: "Mot de passe incorrect.",
  settings: "Paramètres",
  showing: "Afficher",
  from: "de",
  checkbox_device_has_reports: "Les appareils qui ont des rapports",
  view_details: "Voir Plus",
  mattress: "Matelas",
  sleepaid: "Sleepaid",
  number_of_nights: "nombre de nuitées",
  number_of_laids: "Nombre de couchés au lit",
  no_details_available: "Aucun détail disponible",
  heartRate: "Fréquence Cardiaque",
  breathRate: "Fréquence Respiratoire",
  other: "Autre",
  data_deletion: "Suppression des données",
  account_deletion: "Suppression du compte",
  no_user_information: "Aucune informations",
  device_information: "Informations de dispositif",
  contact_information: "Informations de contact",
  change_your_contact_information: "Modifier vos informations de contact",
  edit: "éditer",
  save: "enregistrer",
  contact_email: "Courriel de contact",
  phone_number: "Numéro de téléphone",
  support_email: "Courriel de support",
  push: "envoyer",
  users: "utilisateurs",
  notification: "notification",
  notification_title: "titre de la notification",
  notification_message: "message de la notification",
  all_filtred_users: "tous les utilisateurs filtrés",
  all_filtred_users_except: "tous les utilisateurs filtrés, sauf",
  only_selected_users: "utilisateurs sélectionnés uniquement",
  select_users: "Selectionner les utilisateurs",
  cancel: "Annuler",
  send: "Envoyer",
  your_password: "Votre mot de passe",
  notification_dialog_title: "Confirmation de la notification",
  notification_dialog_message1: "Avant de continuer, veuillez confirmer votre action en entrant votre mot de passe :",
  notification_dialog_message2: "En confirmant, vous autorisez le système à envoyer des notifications aux utilisateurs sélectionnés (:count utilisateurs). Cette action est irréversible.",
  "The password is incorrect.": "Le mot de passe est incorrect.",
  notification_sent_summary: "Votre notification a été envoyée aux utilisateurs sélectionnés.",
  notification_sent_detail: "Notification envoyée",
  notification_failed_summary: "Échec de la notification",
  notification_failed_detail: "Échec de l'envoi de la notification aux utilisateurs",
  notification_body_hint: "Pour afficher le nom de l'utilisateur dans le corps du texte, il vous suffit d'insérer la balise '{name}' dans votre contenu.",
  please_select_users: "Veuillez selectioner des utilisateurs",
  notification_success_message: "Notification a été envoyée avec succès",
  help_url_ar: "Lien de la page d'aide (AR)",
  help_url_en: "Lien de la page d'aide (EN)",
  help_url_fa: "Lien de la page d'aide (FA)",
  help_url_fr: "Lien de la page d'aide (FR)",
  privacy_policy_url_ar: "Politique de confidentialité (AR)",
  privacy_policy_url_en: "Politique de confidentialité (EN)",
  privacy_policy_url_fa: "Politique de confidentialité (FA)",
  privacy_policy_url_fr: "Politique de confidentialité (FR)",
  advice: "Conseils",
  created_at: "Ajouté le",
  add_new: "Ajouter Nouveau",
  confirmation: 'Confirmation',
  'Are you sure you want to delete this advice?': 'Êtes-vous sûr de vouloir supprimer ce conseil ?',
  language_code: 'Code de la Langue (en, fr, ar...)',
  content: 'Contenu',
  new_language: 'Nouvelle Langue',
  adv: "Conseil",
  fun: "Fun",
  rel_info: "Information"
};

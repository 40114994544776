<template>
  <apexchart
    type="treemap"
    class="w-full"
    height="150"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
import locales from "@/locales/apexcharts";
export default {
  props: ["data"],
  data() {
    return {
      series: [
        {
          data: this.data,
        },
      ],
      chartOptions: {
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        legend: {
          show: false,
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: this.$store.getters.isRTL
              ? "Almarai"
              : "Helvetica, Arial, sans-serif",
          },
          formatter: function (text, op) {
            return [text, op.value + "%"];
          },
          offsetY: -4,
        },
        colors: ["#a45a52", "#bf0a30", "#8d021f", "#5e1914"],
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: (v) => v + "%",
          },
        },
        grid: {
          padding: {
            right: -10,
            left: 10,
          },
        },
      },
    };
  },
};
</script>
import {createI18n} from "vue-i18n";
import messages from "@intlify/vite-plugin-vue-i18n/messages";
import store from "./store";

import en_US from './locales/en_US.js';
import ar_MA from './locales/ar_MA.js';
import fr_FR from './locales/fr_FR.js';

const i18n = createI18n({
    locale: store.state.locale?.code || "en_US",
    fallbackLocale: store.state.locale?.code || "en_US",
    messages: {
        en_US,
        ar_MA,
        fr_FR,
    },
});

export default i18n;

<template>
  <div class="grid">
    <span class="text-left px-4 pt-2 text-sm font-semibold capitalize">
      {{ $t("chart_cutomers_by_city") }}
    </span>
    <apexchart
      v-if="series"
      class="px-3"
      type="bar"
      :options="chartOptions"
      :series="series"
      height="350"
    ></apexchart>
  </div>
  <!-- <div v-else class="animate-pulse h-80 bg-gray-200"></div> -->
</template>
<script>
import axios from "axios";
import locales from "@/locales/apexcharts";
export default {
  props: ["series", "labels"],
  data() {
    return {
      // series: this.series,
      chartOptions: {
        // title: {
        //   text: "Number of customers by city",
        // },
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        legend: {
          markers: { offsetX: this.$store.getters.isRTL ? 5 : 0 },
          itemMargin: { horizontal: 10 },
        },
        labels: this.labels,
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        xaxis: {
          labels: {
            offsetY: this.$store.getters.isRTL ? 50 : 0,
            offsetX: this.$store.getters.isRTL ? -15 : 0,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (v) => (v > 0 ? v : ""),
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };
  },
};
</script>
<style scoped>
</style>
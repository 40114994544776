<template>
  <div class="grid">
    <span class="text-left px-4 pt-2 text-sm font-semibold capitalize">
      {{ $t("chart_registred_users_by_day") }}
    </span>
    <apexchart
      v-if="series"
      class="px-3"
      type="area"
      :options="chartOptions"
      :series="series"
      height="350"
      width="100%"
    ></apexchart>
  </div>
  <!-- <div v-else class="animate-pulse h-80 bg-gray-200"></div> -->
</template>
<script>
import axios from "axios";
import locales from "../../locales/apexcharts";
export default {
  props: ["data", "labels"],
  data() {
    return {
      series: [{ name: this.$t("user", 2), data: this.data }],
      chartOptions: {
        labels: this.labels,
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
          stacked: false,
          toolbar: {
            export: {
              png: {},
            },
            autoSelected: "zoom",
          },
        },
        strock: {
          width: 1,
          colors: ["#000"],
        },
        dataLabels: { enabled: false },
        tooltip: { shared: false },
        markers: { size: 0 },
        // title: {
        //   text: "Registred users by day",
        //   align: "left",
        // },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          },
        },
        yaxis: {
          title: {
            text: this.$t("user", 2),
            offsetX: this.$store.getters.isRTL ? -30 : 0,
            style: {
              cssClass: "capitalize font-semibold text-xs",
            },
          },
        },
        xaxis: {
          type: "datetime",
          style: {
            fontFamily: "Almarai, sans-serif",
          },
        },
        tooltip: {
          style: {
            fontFamily: "Almarai, sans-serif",
          },
        },
      },
    };
  },

  mounted() {
    // console.log(this.data, this.labels)
    // this.series = [{ name: "Users", data: this.data }];
    // this.chartOptions.labels = this.labels;
    // axios
    //   .get("dashboard/users-per-day")
    //   .then((res) => {
    //     this.series = [
    //       {
    //         name: "Users",
    //         data: res.data.data,
    //       },
    //     ];
    //     this.chartOptions.labels = res.data.labels;
    //   })
    //   .catch((err) => console.error(err));
  },
};
</script>
<style lang="">
</style>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";

import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/tailwind-light/theme.css";
import "primeicons/primeicons.css";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import moment from "moment";

import "./assets/main.css";
import i18n from "./i18n";
import * as Sentry from "@sentry/vue";

const subdomain = window.location.hostname.split(".").slice(0, -2).join(".");
axios.defaults.baseURL = import.meta.env.VITE_API_URL.replace(
    "{subdomain}",
    subdomain
);
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("access_token");
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.timeout = 20000;

const app = createApp(App);

if(process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://322bb5d7920a2f9c1c2162385aaeffd9@o4506359435362304.ingest.sentry.io/4506399511674880",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
  
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(router);
app.use(i18n);
app.use(store);
app.use(VueApexCharts);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);

if (store.state.configs.isRTL) {
  document.documentElement.setAttribute("dir", "rtl");
}

moment.locale(store.state.locale.code);

app.mount("#app");

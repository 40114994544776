<script setup>
import AlertError from "../../../components/alerts/AlertError.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import ConfirmDialog from 'primevue/confirmdialog';

import {useConfirm} from "primevue/useconfirm";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const route = useRoute()
const router = useRouter()
const store = useStore()
const confirm = useConfirm();
const {t} = useI18n()

const adviceList = computed(() => store.state.advice.adviceList)
const loading = computed(() => store.state.advice.loadingList)
const errorMessage = computed(() => store.state.advice.errorMessage)
const filters = ref({page: 1, search: ''})
const deletingIndex = ref()
const clearError = () => store.state.advice.errorMessage = ''

async function applyFilters() {
  await router.replace({
    name: "advice.index",
    query: {...route.query, ...filters.value},
  });
  await store.dispatch("advice/index", filters.value)
}

function onPage(event) {
  filters.value.page = event.page + 1;
  applyFilters();
}

function deleteAdvice(advice, index) {
  confirm.require({
    header: t('confirmation'),
    message: t('Are you sure you want to delete this advice?'),
    acceptLabel: t('yes'),
    rejectLabel: t('cancel'),
    onShow: () => {
      deletingIndex.value = index;
    },
    reject: () => {
      deletingIndex.value = undefined;
    },
    accept: async () => {
      console.log(`delete ${advice.id}`)
      const ok = await store.dispatch("advice/remove", advice.id)
      deletingIndex.value = undefined
      if(ok) {
        await applyFilters()
      }
    }
  });
}

onMounted(() => {
  filters.value = {...filters.value, ...route.query}
  applyFilters()
})
</script>

<template>
  <div class="flex flex-col gap-5">
    <AlertError :message="errorMessage" @close="clearError"/>

    <!--  Search bar and Add Button  -->
    <div class="flex gap-2 items-center">
      <span
          class="flex-1 flex items-center bg-white rounded-lg shadow-sm border focus-within:outline focus-within:outline-1">
        <i class="pi pi-search ps-4"/>
        <input
            class="w-full outline-none placeholder:capitalize"
            :placeholder="$t('search')"
            v-model="filters.search"
            @change="applyFilters"
        />
      </span>
      <router-link :to="{name: 'advice.create'}">
        <button class="rounded-lg bg-neutral-800 text-neutral-100">
          {{ $t('add_new') }}
        </button>
      </router-link>
    </div>

    <!-- DataTable -->
    <DataTable :lazy="true" :loading="loading" :value="adviceList.data" stripedRows responsiveLayout="scroll"
               class="border rounded-md border-gray-200 overflow-hidden" ref="dt">

      <!-- Advice Identifier -->
      <Column field="id" header="#"></Column>

      <!-- Type of Content -->
      <Column field="type" :header="$t('type')"></Column>

      <!-- Image -->
      <Column :header="$t('image')">
        <template #body="{ data }">
          <img v-if="data.advice.picture_url" :src="data.advice.picture_url" alt="image"
               class="w-16 rounded-lg border aspect-square object-cover"/>
          <span v-else>-</span>
        </template>
      </Column>

      <!-- Advice Content -->
      <Column :header="$t('advice')">
        <template #body="{ data }">
          <ul v-if="data.type === 'adv'" class="flex flex-col gap-2">
            <li v-for="d in data.advice.advices" class="flex gap-2 items-center">
              <small class="rounded border w-6 h-6 grid place-items-center font-bold uppercase">{{ d.lang }}</small>
              <span>{{ d.advice }}</span>
            </li>
          </ul>
          <ul v-if="data.type === 'rel_info'" class="flex flex-col gap-2">
            <li v-for="d in data.advice.related_info" class="flex gap-2 items-center">
              <small class="rounded border w-6 h-6 grid place-items-center font-bold uppercase">{{ d.lang }}</small>
              <span>{{ d.info }}</span>
            </li>
          </ul>
          <ul v-if="data.type === 'fun'" class="flex flex-col gap-2">
            <li v-for="d in data.advice.fun_stuff" class="flex gap-2 items-center">
              <small class="rounded border w-6 h-6 grid place-items-center font-bold uppercase">{{ d.lang }}</small>
              <span>{{ d.fun }}</span>
            </li>
          </ul>
        </template>
      </Column>

      <!-- Created At-->
      <Column :header="$t('created_at')">
        <template #body="{ data }">
          {{ moment(data.created_at).format("L") }}
        </template>
      </Column>

      <!-- Actions -->
      <Column>
        <template #body="{ data, index }">
          <div class="flex gap-2">
            <router-link :to="{name: 'advice.edit', params: {id: data.id}}">
              <button class="bg-blue-600 bg-opacity-25 text-blue-700">
                <i class="pi pi-pencil"></i>
              </button>
            </router-link>
            <button @click="deleteAdvice(data, index)"
                    class="bg-red-600 bg-opacity-25 text-red-700 disabled:bg-neutral-200 disabled:text-neutral-500"
                    :disabled="deletingIndex === index">
              <i v-if="deletingIndex === index" class="pi pi-spinner pi-spin"></i>
              <i v-else class="pi pi-trash"></i>
            </button>
          </div>
        </template>
      </Column>


      <template #footer>
        <div class="sm:flex items-center justify-between">
        <span class="text-sm">
          {{ adviceList.data?.length }} / {{ adviceList.total }} {{ $t("advice") }}
        </span>
          <Paginator :rows="adviceList.per_page" :totalRecords="adviceList.total"
                     :first="(adviceList.current_page - 1) * adviceList.per_page" class="bg-transparent p-0"
                     @page="onPage">
          </Paginator>
        </div>
      </template>
    </DataTable>
  </div>

  <ConfirmDialog id="confirm"/>
</template>

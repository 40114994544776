<script setup>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref} from "vue";

const route = useRoute()
const router = useRouter()
const store = useStore()
const {t} = useI18n()

const saving = ref(false)

const types = computed(() => store.state.advice.types);

const payload = computed(() => {
  return {
    type: type.value,
    advice: {
      picture_url: imageUrl.value,
      advices: translations.value,
    }
  }
})
const selectedAdvice = computed(() => store.state.advice.selectedAdvice)

const type = ref(types.value[0])
const imageUrl = ref(null)
const translations = ref([{lang: 'en'}])

function addTranslation() {
  translations.value.push({lang: '', content: ''})
}

function deleteTranslation(index) {
  translations.value.splice(index, 1)
}

async function submit() {
  saving.value = true
  if (route.params.id) {
    let ok = await store.dispatch("advice/update", {...payload.value, id: route.params.id})
    if (ok) router.back()
  } else {
    let ok = await store.dispatch("advice/store", payload.value)
    if (ok) router.back()
  }
  saving.value = false
}

onMounted(() => {
  if (route.params.id) {
    store.dispatch("advice/show", route.params.id).then(res => {
      type.value = selectedAdvice.value.type
      imageUrl.value = selectedAdvice.value.advice.picture_url
      translations.value = selectedAdvice.value.advice.advices
    })
  }
})
</script>

<template>
  <div class="grid gap-2">
    <div class="flex justify-end">
      <button @click="submit"
              class="bg-blue-800 hover:bg-blue-900 text-white capitalize px-5 py-2 disabled:bg-neutral-300"
              :disabled="saving">
        <span v-if="saving">{{ t('saving') }}</span>
        <span v-else>{{ t('save') }}</span>
      </button>
    </div>
    <fieldset class="bg-white rounded-lg border p-5 grid md:grid-cols-2 gap-2 items-end">
      <legend class="font-semibold text-sm px-2">Type & Image</legend>
      <label class="flex flex-col gap-1">
        <span>{{ t('type') }}</span>
        <select class="w-full bg-white px-4 py-3 rounded-lg border">
          <option v-for="type in types" :value="type">{{ t(type) }}</option>
        </select>
      </label>

      <div class="flex w-full">
        <label class="flex flex-col gap-1 w-full">
          <span>{{ t('image_url') }}</span>
          <input v-model="imageUrl" type="url" class="rounded-lg border px-4 py-2.5 w-full"
                 placeholder="https://example.com/image.jpeg">
        </label>
      </div>
    </fieldset>

    <fieldset class="bg-white rounded-lg border p-5">
      <legend class="font-semibold text-sm px-2">Translations</legend>
      <div class="grid gap-6">
        <div v-for="(trans, index) in translations" class="flex flex-col gap-2">
          <div class="flex justify-between">
            <input v-model="trans.lang" class="rounded-lg border w-fit p-2" :placeholder="t('language_code')"/>
            <button v-if="translations.length > 1" @click="deleteTranslation(index)"
                    class="rounded bg-red-500 bg-opacity-15 pi pi-times text-red-500"></button>
          </div>
          <textarea v-if="type === 'adv'" v-model="trans.advice" type="url" class="rounded-lg border px-4 py-2.5 w-full"
                    rows="4"
                    :placeholder="t('content')"></textarea>
          <textarea v-else-if="type === 'rel_info'" v-model="trans.info" type="url"
                    class="rounded-lg border px-4 py-2.5 w-full" rows="4"
                    :placeholder="t('content')"></textarea>
          <textarea v-else-if="type === 'fun'" v-model="trans.fun" type="url"
                    class="rounded-lg border px-4 py-2.5 w-full" rows="4"
                    :placeholder="t('content')"></textarea>
        </div>
      </div>
      <div class="flex mt-4">
        <button @click="addTranslation" class="bg-neutral-800 text-neutral-200 p-2">{{ t('new_language') }}</button>
      </div>
    </fieldset>
  </div>
</template>

<style scoped>

</style>
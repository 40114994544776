<template>
  <apexchart
    v-if="data"
    type="line"
    height="300"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import locales from "@/locales/apexcharts";
export default {
  props: ["data", "min", "max"],
  data() {
    return {
      series: [
        {
          name: "",
          data: this.data,
        },
      ],
      chartOptions: {
        chart: {
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        stroke: { width: 2, curve: "smooth" },
        dataLabels: { enabled: false },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              fontFamily: this.$store.getters.isRTL
                ? "Almarai"
                : "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          min: this.min ?? 0,
          max: this.max,
          labels: {
            offsetX: this.$store.getters.isRTL ? -20 : 0,
            formatter: (v) => Math.floor(v),
          },
        },
        tooltip: {
          x: { format: "HH:mm" },
        },
        // colors: ["#B9412F"],
      },
    };
  },
};
</script>
<template>
    <Reports :userId="userId" />
</template>
<script>
import Reports from "../reports/Reports.vue";
export default {
  components: { Reports },
  data() {
    return {
      userId: this.$route.params.user_id,
    };
  },
};
</script>

<style>
</style>
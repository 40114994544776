export default [
    {
        "name": "fr_FR",
        "options": {
            "months": [
                "janvier",
                "février",
                "mars",
                "avril",
                "mai",
                "juin",
                "juillet",
                "août",
                "septembre",
                "octobre",
                "novembre",
                "décembre"
            ],
            "shortMonths": [
                "janv.",
                "févr.",
                "mars",
                "avr.",
                "mai",
                "juin",
                "juill.",
                "août",
                "sept.",
                "oct.",
                "nov.",
                "déc."
            ],
            "days": [
                "dimanche",
                "lundi",
                "mardi",
                "mercredi",
                "jeudi",
                "vendredi",
                "samedi"
            ],
            "shortDays": ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
            "toolbar": {
                "exportToSVG": "Télécharger au format SVG",
                "exportToPNG": "Télécharger au format PNG",
                "exportToCSV": "Télécharger au format CSV",
                "menu": "Menu",
                "selection": "Sélection",
                "selectionZoom": "Sélection et zoom",
                "zoomIn": "Zoomer",
                "zoomOut": "Dézoomer",
                "pan": "Navigation",
                "reset": "Réinitialiser le zoom"
            }
        }
    },
    {
        "name": "en_US",
        "options": {
            "months": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            "shortMonths": [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ],
            "days": [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "toolbar": {
                "exportToSVG": "Download SVG",
                "exportToPNG": "Download PNG",
                "exportToCSV": "Download CSV",
                "menu": "Menu",
                "selection": "Selection",
                "selectionZoom": "Selection Zoom",
                "zoomIn": "Zoom In",
                "zoomOut": "Zoom Out",
                "pan": "Panning",
                "reset": "Reset Zoom"
            }
        }
    },
    {
        "name": "ar_MA",
        "options": {
            "months": [
                "يناير",
                "فبراير",
                "مارس",
                "أبريل",
                "ماي",
                "يونيو",
                "يوليوز",
                "غشت",
                "شتنبر",
                "أكتوبر",
                "نونبر",
                "دجنبر"
            ],
            "shortMonths": [
                "يناير",
                "فبراير",
                "مارس",
                "أبريل",
                "ماي",
                "يونيو",
                "يوليوز",
                "غشت",
                "شتنبر",
                "أكتوبر",
                "نونبر",
                "دجنبر"
            ],
            "days": [
                "الأحد",
                "الإثنين",
                "الثلاثاء",
                "الأربعاء",
                "الخميس",
                "الجمعة",
                "السبت"
            ],
            "shortDays": [
                "الأحد",
                "الإثنين",
                "الثلاثاء",
                "الأربعاء",
                "الخميس",
                "الجمعة",
                "السبت"
            ],
            "toolbar": {
                "exportToSVG": "تحميل بصيغة SVG",
                "exportToPNG": "تحميل بصيغة PNG",
                "exportToCSV": "تحميل بصيغة CSV",
                "menu": "القائمة",
                "selection": "تحديد",
                "selectionZoom": "تكبير التحديد",
                "zoomIn": "تكبير",
                "zoomOut": "تصغير",
                "pan": "تحريك",
                "reset": "إعادة التعيين"
            }
        }
    }
]
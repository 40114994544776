<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from "primevue/badge";
import Paginator from "primevue/paginator";
import Button from "primevue/button";
import axios from "axios";
import moment from "moment";
import {ref, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import {useStore} from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

const loading = ref(null);
const search = ref(route.query.search ?? "");
const page = ref(Number(route.query.page ?? 1));
const totalCount = ref(null);
const perPage = ref(null);
const users = ref(null);

const onPage = (event) => {
  page.value = event.page + 1;
  router.replace({
    name: "Users",
    query: {...route.query, page: event.page + 1},
  });
  fetch();
};

const filter = () => {
  page.value = 1;
  router.replace({
    name: "Users",
    query: {search: search.value},
  });
  fetch();
};

const fetch = async () => {
  loading.value = true;
  let response = null;
  let url = "users?page=" + page.value + "&search=" + search.value;

  if (store.state.users.onlyHasDevice) {
    url += "&has_device=" + store.state.users.onlyHasDevice;
  }

  await axios
      .get(url)
      .then((res) => (response = res))
      .catch((err) => (response = err.response));

  if (response.status == 200) {
    page.value = response.data.current_page;
    totalCount.value = response.data.total;
    perPage.value = response.data.per_page;
    users.value = response.data.data;
  }

  loading.value = false;
};

onMounted(() => {
  fetch();
});
</script>

<template>
  <DataTable :lazy="true" :loading="loading" :value="users" stripedRows responsiveLayout="scroll"
             class="border rounded-md border-gray-200 text-sm">
    <template #header>
      <div class="flex items-center gap-3 p-0">
        <span class="flex items-center bg-white rounded-lg shadow-sm">
          <i class="pi pi-search ps-3"/>
          <input class="outline-none" type="text" :placeholder="$t('placeholder_users_search')" v-model="search"
                 @change="filter()"/>
        </span>
        <div class="p-field-checkbox flex items-center gap-1 capitalize">
          <input type="checkbox" v-model="$store.state.users.onlyHasDevice"
                 name="only_has_device" id="only_has_device" @change="filter()"/>
          <label for="only_has_device">
            {{ $t("checkbox_users_with_devices") }}
          </label>
        </div>
        <div class="ml-auto">
          <router-link :to="{ name: 'Notify' }">
            <Button :label="$t('notify')" class="p-button-sm bg-indigo-600 text-white"/>
          </router-link>
        </div>
      </div>
    </template>

    <!-- <Column field="id" header="ID"></Column> -->

    <!-- Email -->
    <Column field="email" :header="$t('email')"></Column>

    <!-- Devices related to the user -->
    <Column field="userdevices" :header="$t('device', 2)">
      <template #body="{ data }">
        <span v-if="data.userdevices_count > 0" class="grid font-semibold">
          <div class="d-flex justify-items-center" v-for="userdevice in data.userdevices" :key="userdevice.id">
            <span class="text-xs">{{ userdevice.device.name }}</span>
            <router-link :to="{
              name: 'DeviceDetails',
              params: { id: userdevice.device.id },
            }">
              <i class="pi pi-external-link mx-0.5" style="font-size: 0.75rem"></i>
            </router-link>
          </div>
        </span>
        <span v-else>-</span>
      </template>
    </Column>

    <!-- Reports count + Link to user reports -->
    <Column field="reports_count" :header="$t('report', 2)">
      <template #body="{ data }">
        <span v-if="data.reports_count > 0" class="grid font-semibold">
          <div class="d-flex">
            <span class="mx-0.5">{{ data.reports_count }}</span>
            <router-link :to="{ name: 'UserReports', params: { user_id: data.id } }">
              <i class="pi pi-external-link mx-0.5" style="font-size: 0.75rem"></i>
            </router-link>
          </div>
        </span>
        <span v-else>-</span>
      </template>
    </Column>

    <!-- Account Type -->
    <Column field="account_type" :header="$t('account_type')">
      <template #body="{ data }">
        <badge class="rounded" :value="
          data.account_type == 0
            ? 'Email'
            : data.account_type == 1
              ? 'Facebook'
              : data.account_type == 2
                ? 'Google'
                : data.account_type == 3
                  ? 'Apple'
                  : ''
        " :class="{
  'bg-green-200 text-green-700': data.account_type == 0,
  'bg-blue-200 text-blue-700': data.account_type == 1,
  'bg-red-200 text-red-700': data.account_type == 2,
  'bg-gray-300 text-gray-700': data.account_type == 3,
}"/>
      </template>
    </Column>

    <!-- Role -->
    <Column field="role" :header="$t('role')">
      <template #body="{ data }">
        <badge :value="data.roles[0].name" class="rounded-sm uppercase" :class="{
          'bg-gray-300 text-gray-700': data.roles[0].name === 'guest',
          'bg-green-200 text-green-700': data.roles[0].name === 'customer',
          'bg-red-200 text-red-700': data.roles[0].name === 'admin',
        }"/>
      </template>
    </Column>

    <!-- Account Email Status -->
    <Column field="verified" :header="$t('account_status')">
      <template #body="{ data }">
        <i class="pi" :class="
          data.verified
            ? 'false-icon pi-times-circle text-red-700'
            : 'true-icon pi-check-circle text-green-500'
        "/>
      </template>
    </Column>

    <!-- User Registred At -->
    <Column field="created_at" :header="$t('registration_date')">
      <template #body="{ data }">{{ moment(data.created_at).startOf("minute").fromNow() }}</template>
    </Column>

    <!-- Actions -->
    <Column>
      <template #body="{ data }">
        <button class="bg-green-600 bg-opacity-25 text-green-700"
                @click="router.push({ name: 'UserDetails', params: { id: data.id } })">
          <i class="pi pi-eye"></i>
        </button>
      </template>
    </Column>

    <template #footer>
      <div class="sm:flex items-center justify-between">
        <span class="text-sm">
          {{ users?.length }} / {{ totalCount }} {{ $t("user") }}
        </span>
        <Paginator :rows="perPage" :totalRecords="totalCount" :first="(page - 1) * perPage" class="bg-transparent p-0"
                   @page="onPage($event)"
        ></Paginator>
      </div>
    </template>

    <template #empty>
      <div class="text-center p-5 font-semibold">No users found to show.</div>
    </template>
  </DataTable>
</template>

<style scoped>

</style>

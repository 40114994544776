<template>
  <apexchart
    type="radialBar"
    :options="chartOptions"
    :series="[score]"
  ></apexchart>
</template>

<script>
import locales from '@/locales/apexcharts'
export default {
  props: ["score"],
  data() {
    return {
      chartOptions: {
        chart: {
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          locales: [...locales],
          defaultLocale: this.$store.state.locale?.code || "en_US",
        },
        plotOptions: {
          radialBar: {
            startAngle: -120,
            endAngle: 120,
            track: { margin: 5 },
            dataLabels: {
              name: { show: false },
              value: {
                offsetY: 5,
                fontSize: "18px",
              },
            },
          },
        },
        grid: {
          padding: { top: -10, bottom: 20 },
        },
        stroke: { lineCap: "round" },
        colors: ["#2ecc71"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#c0392b"],
            stops: [0, 100],
          },
        },
      },
    };
  },
};
</script>
<template>
  <div class="grid grid-cols-2 gap-3 animate-pulse" v-if="loading">
    <div class="h-60 w-full bg-gray-100 rounded-md"></div>
    <div class="h-60 w-full bg-gray-100 rounded-md"></div>
    <div class="h-96 w-full col-span-2 bg-gray-100 rounded-md"></div>
  </div>
  <div
    class="flex gap-4 items-start justify-between h-full mb-[200px] w-full"
    v-else-if="devices?.length > 0"
  >
    <div v-for="device in devices" :key="device" class="grid gap-3 flex-1">
      <div
        v-if="isDouble && devices.length === 1"
        class="px-5 py-6 bg-blue-500 bg-opacity-40 text-blue-700 rounded-lg flex gap-3 items-center"
      >
        <i class="bi bi-info-circle-fill text-xl"></i>
        <p>
          <span>This </span>
          <span class="font-bold">{{
            device.device.left_right == 1 ? "Left side" : "Right side"
          }}</span>
          <span> of this device is not bind yet!</span>
        </p>
      </div>
      <div class="flex gap-3">
        <div class="m-card flex flex-col items-center flex-1">
          <Avatar
            size="xlarge"
            shape="circle"
            :label="device.user.name[0]"
            class="bg-gray-500 text-white font-bold uppercase mb-1"
          />
          <span class="text-sm font-semibold">{{ device.user.name }}</span>
          <span class="text-xs font-light">{{ device.user.email }}</span>
          <Badge
            class="rounded mt-2"
            :value="
              device.user.account_type == 0
                ? 'Email'
                : device.user.account_type == 1
                ? 'Facebook'
                : device.user.account_type == 2
                ? 'Google'
                : device.user.account_type == 3
                ? 'Apple'
                : ''
            "
            :class="{
              'bg-green-200 text-green-700': device.user.account_type == 0,
              'bg-blue-200 text-blue-700': device.user.account_type == 1,
              'bg-red-200 text-red-700': device.user.account_type == 2,
              'bg-gray-300 text-gray-700': device.user.account_type == 3,
            }"
          />
        </div>
        <div class="m-card flex flex-col items-center flex-1">
          <i
            class="bi bi-cpu-fill text-6xl mb-1"
            :class="isOnline ? 'text-green-600' : 'text-gray-600'"
          ></i>
          <span class="text-xs">{{ isOnline ? "online" : "offline" }}</span>
          <span class="font-bold">{{ device.device.name }}</span>
          <span class="font-light text-xs mb-1">{{
            device.device.device_type == 38 ? "Sleep Monitor" : "Aroma"
          }}</span>
          <Badge
            v-if="
              device.device.device_type == 38 &&
              device.device.single_or_double == 'D'
            "
            :value="device.device.left_right == 1 ? $t('right') : $t('left')"
            class="rounded bg-purple-300 text-purple-800 font-semibold m-0.5"
          />
        </div>
      </div>
      <div class="m-card" v-if="!isOnline">
        <div class="w-full grid place-items-center p-10">
          <i class="bi bi-dash-circle text-6xl text-gray-700 mb-3"></i>
          <h1 class="text-xl font-semibold text-gray-700">
            Oooooops! No realtime data for this device
          </h1>
          <h1 class="text-md font-light text-gray-600">
            This device is offline, please turn on this device or contact the
            owner
          </h1>
        </div>
      </div>
      <div class="grid gap-4" v-else>
        <div class="m-card">
          <RealtimeMonitoring
            :topics="device.topics"
            dataType="heartRate"
            @statusUpdated="onStatusUpdated($event)"
          />
        </div>
        <div class="m-card">
          <RealtimeMonitoring
            :topics="device.topics"
            dataType="breathRate"
            @statusUpdated="onStatusUpdated($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="w-full h-full grid items-center justify-items-center" v-else>
    <div class="grid place-items-center p-10">
      <i class="bi bi-dash-circle text-6xl text-gray-700 mb-3"></i>
      <span class="text-xl font-semibold text-gray-700">{{
        $t("no_device_details")
      }}</span>
    </div>
  </div>
  <!-- <div style="margin-bottom: 200px"></div> -->
</template>

<script setup>
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import RealtimeMonitoring from "../../../components/charts/RealtimeMonitoring.vue";
import axios from "axios";
import { onMounted, onUnmounted, ref } from "vue";

const props = defineProps(["id"]);

const loading = ref(null);
const devices = ref(null);
const isDouble = ref(false);
const isOnline = ref(false);

function onStatusUpdated(event) {
  isOnline.value = event;
}

onMounted(async () => {
  loading.value = true;
  let response = null;
  await axios
    .get("device/" + props.id)
    .then((res) => (response = res))
    .catch((err) => (response = err.response));
  if (response.status === 200) {
    devices.value = response.data;
    isDouble.value = response.data[0]?.device?.single_or_double === "D";
    await axios
      .get(`device/${props.id}/status`)
      .then((response) => (isOnline.value = response.data.code == 1));
  }
  loading.value = false;
});

onUnmounted(() => {});
</script>

<template>
  <div class="flex flex-row h-full border-r border-gray-200">
    <nav
      class="bg-gray-100 h-screen justify-between flex flex-col"
      :class="isSmall ? 'w-20' : 'w-60'"
    >
      <div class="">
        <div class="flex gap-2 items-center justify-start ms-6 my-5">
          <img v-if="isSmall" class="h-8" src="@/assets/images/sb-logo.svg" />
          <img v-else class="h-8" src="@/assets/images/sb-lg-logo.svg" />
          <!-- <img class="h-8" src="@/assets/images/ibed.png" /> -->
<!--          <div class="flex items-center gap-2">-->
<!--          </div>-->
        </div>
        <ul>
          <li class="text-center" v-for="item in menuItems" :key="item">
            <router-link
              :class="[
                $route.path.includes(item.routeName.toLowerCase())
                  ? 'router-link-active'
                  : 'sst-links-item',
                isSmall ? 'flex flex-col' : '',
              ]"
              :to="{ name: item.routeName, params: {filter: item.filter ?? ''} }"
            >
              <i
                class="bi text-xl font-black"
                :class="[item.icon, isSmall ? '' : 'pl-5']"
              />
              <span
                class="font-semibold capitalize"
                :class="[isSmall ? 'text-xs' : 'mx-4 text-sm']"
              >
                {{ item.title }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- Logout Button -->
      <button
        class="bg-red-200 hover:bg-red-300 cursor-pointer p-4 text-red-700 text-center m-2"
        @click="logout($event)"
        :disabled="loggingOut"
      >
        <i v-if="loggingOut" class="pi pi-spinner pi-spin font-bold"></i>
        <i v-else class="pi pi-power-off font-bold"></i>
        <ConfirmPopup></ConfirmPopup>
      </button>
    </nav>
  </div>
</template>

<script>
import ConfirmPopup from "primevue/confirmpopup";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: { ConfirmPopup },
  props: ["isSmall"],
  data() {
    return {
      loggingOut: null,
      menuItems: [
        {
          title: this.$t("dashboard"),
          routeName: "Dashboard",
          icon: "bi-speedometer",
        },
        {
          title: this.$t("user", 2),
          routeName: "Users",
          icon: "bi-people-fill",
        },
        {
          title: this.$t("device", 2),
          routeName: "Devices",
          icon: "bi-cpu-fill",
        },
        {
          title: this.$t("report", 2),
          routeName: "Reports",
          icon: "bi-pie-chart-fill",
        },
        {
          title: this.$t("inbox"),
          routeName: "Messages",
          filter: "unresolved",
          icon: "bi-envelope-fill",
        },
        {
          title: this.$t("advice"),
          routeName: "advice.index",
          icon: "bi-list-check",
        },
        {
          title: this.$t("settings"),
          routeName: "Settings",
          icon: "bi-gear-fill",
        },
      ],
    };
  },
  methods: {
    logout(event) {
      Swal.fire({
        title: this.$t("logout_confirm_message"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        confirmButtonColor: "red",
      }).then(async (v) => {
        if (v.isConfirmed) {
          let response = null;
          this.loggingOut = true;
          await axios
            .delete("logout")
            .then((res) => (response = res))
            .catch((err) => (response = err.response));
          this.loggingOut = false;
          if (response.status == 200) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("expired_in");
            localStorage.removeItem("token_type");
            location.reload();
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.router-link-active {
  @apply w-full text-neutral-900 flex items-center p-2 my-2 transition-colors duration-200 justify-start border-r-2 border-gray-800 bg-gray-200;
}

.sst-links-item {
  @apply w-full text-neutral-500 flex items-center p-2 my-2 transition-colors duration-200 justify-start border-r-2 border-transparent hover:text-gray-800;
}
</style>

<template>
  <div
      :dir="isRTL ? 'rtl' : 'ltr'"
      :class="{'font-arabic': isRTL}"
  >
    <router-view/>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isRTL: this.$store.getters.isRTL,
    };
  },
};
</script>

export default {
  namespaced: true,
  state: {
    locale: localStorage.locale || "en",
    isRTL: localStorage.locale === "ar",
    theme: localStorage.theme || "light",
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.locale = locale;
      state.isRTL = state.locale == "ar";
      document.documentElement.setAttribute(
        "dir",
        locale === "ar" ? "rtl" : ""
      );
      document.documentElement.setAttribute("lang", locale);
    },

    setTheme(state) {
      if (state.theme === "dark") {
        state.theme = "light";
        localStorage.theme = "light";
      } else {
        state.theme = "dark";
        localStorage.theme = "dark";
      }

      if (
        state.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      ) {
        document.documentElement.setAttribute("class", "dark");
      } else {
        document.documentElement.setAttribute("class", "light");
      }
    },
  },
  actions: {},
  modules: {},
};

<template>
  <div v-if="item" class="bg-gray-50 h-full w-full flex flex-col items-start justify-items-stretch">
    <div class="p-5 bg-white shadow-md w-full border-b flex items-center justify-between"
      :class="{ 'flex-row-reverse': $store.getters.isRTL }">
      <button @click="close()">
        <i class="pi pi-chevron-left text-xs text-gray-500"></i>
      </button>
      <div class="flex gap-3 items-center" :class="{ 'flex-row-reverse': $store.getters.isRTL }"
        v-if="$route.params.filter != 'trash'">
        <i class="pi pi-check-square icon" v-if="item.stat.toLowerCase() !== 'resolved'" @click="updateState('resolved')"
          v-tooltip.left="$t('resolved')"></i>
        <i class="pi pi-clock icon" v-if="item.stat.toLowerCase() !== 'unresolved'" @click="updateState('unresolved')"
          v-tooltip.left="$t('unresolved')"></i>
        <i class="pi pi-trash icon" @click="move2trash()" v-tooltip.left="$t('move_to_trash')"></i>
        <i class="pi pi-bars icon capitalize" @click="toggle" v-tooltip.left="$t('more') + '...'"></i>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true">
          <template #item="{ item, props }">
            <a class="flex items-center gap-2" v-bind="props.action">
              <span class="w-2 h-2 rounded-full" :class="item.color"></span>
              <span class="capitalize">{{ item.label }}</span>
            </a>
          </template>
        </Menu>
      </div>
      <div v-else class="flex gap-3 items-center">
        <i class="pi pi-refresh icon capitalize" v-tooltip.left="$t('restore')" @click="restore()"></i>
        <i class="pi pi-trash icon capitalize" v-tooltip.left="$t('delete_permanently')" @click="forceDelete()"></i>
      </div>
    </div>
    <div class="p-6 w-full h-full overflow-y-scroll">
      <div class="flex gap-2">
        <span class="badge capitalize" :class="item.severity == 'low'
          ? 'bg-yellow-200 text-yellow-600'
          : item.severity == 'medium'
            ? 'bg-blue-100 text-blue-700'
            : 'bg-red-100 text-red-700'
          ">
          {{ $t(item.severity.toLowerCase()) }}
        </span>
        <span class="badge capitalize" :class="item.stat.toLowerCase() == 'resolved'
          ? 'bg-green-200 text-green-600'
          : 'bg-red-100 text-red-700'
          ">
          {{ $t(item.stat.toLowerCase()) }}
        </span>
        <span class="badge capitalize bg-neutral-200 text-neutral-600">
          {{ $t(item.reason) }}
        </span>
      </div>
      <div class="mt-6 bg-white shadow-md border rounded-md w-full grid">
        <div class="flex items-start justify-between border-b p-4">
          <div class="flex items-center gap-2">
            <Avatar label="B" size="large" shape="circle" class="text-white font-semibold bg-gray-600" />
            <div class="grid">
              <span class="font-medium"> {{ item.sender_name }} </span>
              <span class="text-gray-800 text-sm">
                {{ item.email }}
              </span>
              <span class="text-gray-800 text-sm font-bold">
                {{ item.phone_number }}
              </span>
            </div>
          </div>
          <span class="text-gray-400 font-light text-xs">
            {{ useMoment(item.created_at).startOf("minute").fromNow() }}
          </span>
        </div>
        <div class="py-4 px-6 text-justify text-gray-600 text-sm">
          <div v-html="item.message"></div>
        </div>
      </div>
      <div class="mt-6 bg-white shadow-md border rounded-md w-full grid p-5 gap-3">
        <h1 class="uppercase font-bold text-neutral-500">
          {{ $t("user_information") }}
        </h1>
        <span v-if="!item.user" class="text-center p-5 font-medium">{{ $t('no_user_information') }}</span>
        <table v-else>
          <tr>
            <td class="font-bold capitalize p-2">{{ $t("name") }}</td>
            <td>{{ item.user.name }}</td>
          </tr>

          <tr>
            <td class="font-bold capitalize p-2">{{ $t("email") }}</td>
            <td>{{ item.user.email }}</td>
          </tr>

          <tr>
            <td class="font-bold capitalize p-2">
              {{ $t("device_information") }}
            </td>
            <td class="grid gap-2">
              <span v-if="item.user.userdevices.length == 0">{{ $t('no_devices') }}</span>
              <div class="flex flex-wrap gap-5">
                <div v-for="d in item.user.userdevices" class="flex flex-col border px-4 py-1 rounded-xl">
                  <div class="flex gap-2">
                    <span>{{ d.deviceName_user }}</span>
                    <span v-if="d.device.name.startsWith('M800')"
                      class="rounded-full font-bold px-2 grid place-items-center text-xs bg-neutral-300 w-fit uppercase">{{
                        d.left_right ? $t("left") : $t("right") }}</span>
                  </div>
                  <span class="text-sm font-bold">{{ d.device.name }}</span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import Tooltip from "primevue/tooltip";
import moment from "moment";
import axios from "axios";
export default {
  components: { Avatar, Menu },
  directives: {
    tooltip: Tooltip,
  },
  props: ["item"],
  emits: ["close", "refresh"],
  data() {
    return {
      items: [
        {
          label: this.$t("make_as_high"),
          icon: "pi pi-chevron-up",
          color: "bg-red-500",
          command: () => {
            this.updateSeverity("high");
          },
        },
        {
          label: this.$t("make_as_medium"),
          icon: "pi pi-minus",
          color: "bg-blue-500",
          command: () => {
            this.updateSeverity("medium");
          },
        },
        {
          label: this.$t("make_as_low"),
          icon: "pi pi-chevron-down",
          color: "bg-yellow-500",
          command: () => {
            this.updateSeverity("low");
          },
        },
      ],
    };
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    close() {
      this.$emit("close");
    },
    useMoment(date) {
      return moment(date);
    },
    async restore() {
      await axios
        .put("mail/restore/" + this.item.id)
        .then((res) => {
          this.item.deleted_at = res.data.deleted_at;
          this.$emit("refresh");
          this.$emit("close");
        })
        .catch();
    },
    async forceDelete() {
      await axios
        .delete("mail/force/" + this.item.id)
        .then(() => {
          this.$emit("close");
          this.$emit("refresh");
        })
        .catch();
    },
    async updateState(state) {
      await axios
        .put("mail/" + this.item.id, { stat: state })
        .then((res) => {
          console.log(res.status, res.data);
          this.item.stat = state;
          this.$emit("refresh");
          this.$emit("close");
        })
        .catch((err) => console.log(err));
    },
    async updateSeverity(severity) {
      await axios
        .put("mail/" + this.item.id, { severity: severity })
        .then((res) => {
          console.log(res.status, res.data);
          this.item.severity = severity;
          this.$emit("refresh");
        })
        .catch((err) => console.log(err));
    },
    async move2trash() {
      await axios
        .delete("mail/" + this.item.id)
        .then((res) => {
          console.log(res.status, res.data);
          this.$emit("refresh");
          this.$emit("close");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style scoped>
.badge {
  @apply px-3 py-1 rounded-full text-xs font-semibold;
}

.icon {
  @apply font-bold text-gray-700 cursor-pointer hover:text-black;
}
</style>

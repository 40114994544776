import moment from 'moment'

export default {
    namespaced: true,
    state: {
        dateRange: [new Date(moment().subtract(6, "days")), new Date()],
        scoreRange: [0, 100],
    },
    mutations: {

    }
}
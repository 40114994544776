<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';

const data = ref({})
const editKey = ref()
const saving = ref(false)
const successSavedKey = ref()
const failedSaveKey = ref()
const errorMessage = ref()

function setEditKey(k) {
    editKey.value = k
}

function restoreSuccessSavedKey() {
    successSavedKey.value = null
    clearTimeout()
}

async function saveChanges(k) {
    saving.value = true
    failedSaveKey.value = null
    errorMessage.value = null

    try {
        await axios.put('appsettings', { key: k, value: data.value[k] })
        successSavedKey.value = k
        setTimeout(restoreSuccessSavedKey, 2000);
        editKey.value = null
        failedSaveKey.value = null
        errorMessage.value = null
    } catch (err) {
        console.error(err.message);
        failedSaveKey.value = k
        errorMessage.value = err.message
    }

    saving.value = false
}

onMounted(() => {
    axios.get("appsettings")
        .then(res => {
            data.value = res.data;
        })
        .catch(err => {

        })
})
</script>

<template>
    <div class="border bg-white rounded-lg shadow py-5">
        <div class="px-10">
            <h1 class="text-xl font-semibold mb-1">{{ $t("contact_information") }}</h1>
            <h1 class="text-md font-semibold text-gray-400">
                {{ $t("change_your_contact_information") }}
            </h1>
        </div>
        <hr class="my-6" />
        <div class="px-10 flex flex-col gap-5">
            <label class="grid" v-for="k in Object.keys(data)" :key="k">
                <span class="text-semibold">{{ $t(k) }}</span>
                <div class="flex gap-2 items-center">
                    <div class="relative w-full">
                        <input autofocus type="text" v-model="data[k]" :readonly="editKey !== k" :disabled="editKey !== k"
                            class="border border-gray-300 bg-gray-50 rounded-lg p-2.5 w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" data-slot="icon" :class="{ 'opacity-100': successSavedKey === k }"
                            class="absolute w-7 h-7 text-green-700 top-2 end-2 transition duration-300 opacity-0">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <span v-if="failedSaveKey === k"
                            class="absolute font-semibold text-sm top-0 h-full grid place-items-center end-2 text-red-700">
                            {{ errorMessage }}
                        </span>
                    </div>
                    <button v-if="editKey !== k" @click="setEditKey(k)"
                        class="py-2.5 px-3 bg-neutral-800 hover:bg-neutral-700 text-neutral-200 uppercase">
                        {{ $t('edit') }}
                    </button>
                    <button v-else @click="saveChanges(k)" :disabled="saving"
                        class="py-2.5 px-3 bg-neutral-800 hover:bg-neutral-700 text-neutral-200 uppercase disabled:bg-neutral-400">
                        {{ $t('save') }}
                    </button>
                </div>
            </label>
        </div>
    </div>
</template>
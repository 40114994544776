import { createStore } from "vuex";
import axios from "axios";
import devices from "./devices";
import users from "./users";
import reports from "./reports";
import auth from "./auth";
import messages from "./messages";
import configs from "./configs";
import advice from "./advice";

const locales = [
  { name: "English", code: "en_US" },
  { name: "العربية", code: "ar_MA" },
  { name: "Français", code: "fr_FR" },
];

const navLang = navigator.language.split("-")[0];

export default createStore({
  state: {
    access_token: localStorage.getItem("access_token"),
    locale:
      JSON.parse(localStorage.getItem("locale")) ??
      locales.find((e) => e.code.startsWith(navLang)) ??
      locales[0],
    locales: locales,
  },
  getters: {
    isRTL(state) {
      return state.locale.code == "ar_MA";
    },
    isAuthenticated: async function (state) {
      try {
        let response = await axios.get("verify", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        console.log("isAuthenticated", response.data);
        return response.status == 200;
      } catch (err) {
        return false;
      }
    },
  },
  mutations: {},
  actions: {},
  modules: {
    devices,
    users,
    reports,
    auth,
    messages,
    configs,
    advice,
  },
});

<template>
  <div
    class="mb-3 grid gap-3 md:grid-cols-3 xl:grid-cols-3 justify-items-stretch"
  >
    <router-link :to="{ name: 'Users' }">
      <summary-card
        :title="$t('user', 2)"
        :count="data?.counts?.users"
        :todayCount="data?.counts?.today_users"
        :series="data?.users?.series"
        :labels="data?.users?.labels"
        class="text-green-700 bg-green-200 border-green-500"
        chartColor="#047857"
      />
    </router-link>

    <router-link
      :to="{ name: 'Devices' }"
      @click="$store.state.devices.status = 'bound'"
    >
      <summary-card
        :title="$t('bound_devices')"
        :count="data?.counts?.bound_devices"
        :todayCount="data?.counts?.today_bound_devices"
        :total="data?.counts?.devices"
        :series="data?.devices?.series"
        :labels="data?.devices?.labels"
        class="text-yellow-700 bg-yellow-200 border-yellow-500"
        chartColor="#B4530A"
      />
    </router-link>

    <router-link :to="{ name: 'Reports' }">
      <summary-card
        :title="$t('report', 2)"
        :count="data?.counts?.reports"
        :todayCount="data?.counts?.today_reports"
        :series="data?.reports?.series"
        :labels="data?.reports?.labels"
        class="text-purple-700 bg-purple-200 border-purple-500"
        chartColor="#6D28D9"
      />
    </router-link>

    <!-- <router-link
      :to="{ name: 'Devices' }"
      @click="$store.state.devices.onlyOnline = true"
    >
      <summary-card
        :title="$t('online_devices')"
        :count="$store.state.devices.onlineDevices.length"
        :total="data?.counts?.bound_devices"
        class="text-red-700 bg-red-200 border-red-500"
        chartColor="#B91C1C"
      />
    </router-link> -->
  </div>
</template>

<script>
import SummaryCard from "../../../components/dashboard/SummaryCard.vue";

export default {
  components: {
    SummaryCard,
  },
  props: ["data"],
};
</script>
<template>
  <Summary v-if="summary.messages" :data="summary" />
  <div
    v-else
    class="animate-pulse mb-3 grid gap-3 md:grid-cols-2 xl:grid-cols-4"
  >
    <div class="bg-gray-200 rounded-md h-32"></div>
    <div class="bg-gray-200 rounded-md h-32"></div>
    <div class="bg-gray-200 rounded-md h-32"></div>
    <div class="bg-gray-200 rounded-md h-32"></div>
  </div>
  <div class="grid gap-2 md:grid-cols-2 xl:grid-cols-3">
    <!-- Users Per Day -->
    <UsersByDay
      v-if="usersPerDayData"
      :data="usersPerDayData"
      :labels="usersPerDayLabels"
      class="md:col-span-2 bg-white border border-gray-200 rounded-md pt-1"
    />
    <div
      v-else
      class="animate-pulse h-80 bg-gray-200 md:col-span-2 rounded-md pt-1"
    ></div>

    <!-- Devices and Bound devices -->
    <DevicesPercents
      v-if="boundDevicesChartData"
      :data="boundDevicesChartData"
      class="
        bg-white
        border border-gray-200
        rounded-md
        pt-1
        col-span-2
        xl:col-span-1
      "
    />
    <div
      v-else
      class="
        animate-pulse
        h-80
        min-h-full
        rounded-md
        pt-1
        col-span-2
        xl:col-span-1
        bg-gray-200
      "
    ></div>
    <!-- Users By Phone OS -->
    <UsersByPlatform
      v-if="cutomersPerPhoneOSSeries"
      :series="cutomersPerPhoneOSSeries"
      :labels="cutomersPerPhoneOSLabels"
      class="
        bg-white
        border border-gray-200
        rounded-md
        pt-1
        col-span-2
        xl:col-span-1
      "
    />
    <div
      v-else
      class="
        animate-pulse
        h-80
        min-h-full
        rounded-md
        pt-1
        col-span-2
        xl:col-span-1
        bg-gray-200
      "
    ></div>

    <!-- Customers Per City -->
    <NumberOfCustomersByCity
      v-if="customersPerCitySeries"
      :series="customersPerCitySeries"
      :labels="customersPerCityLabels"
      class="bg-white border border-gray-200 rounded-md pt-1 md:col-span-2"
    />
    <div
      class="animate-pulse h-80 bg-gray-200 md:col-span-2 rounded-md pt-1"
      v-else
    ></div>
  </div>
</template>


<script>
import LineChartWithoutAxis from "../../../components/charts/LineChartWithoutAxis.vue";
import UsersByPlatform from "../../../components/charts/UsersByPlatform.vue";
import UsersByDay from "../../../components/charts/UsersByDay.vue";
import NumberOfCustomersByCity from "../../../components/charts/NumberOfCustomersByCity.vue";
import DevicesPercents from "../../../components/charts/DevicesPercents.vue";
import Summary from "./Summary.vue";
import axios from "axios";

export default {
  name: "dashboard",
  components: {
    Summary,
    LineChartWithoutAxis,
    UsersByPlatform,
    UsersByDay,
    NumberOfCustomersByCity,
    DevicesPercents,
  },
  data() {
    return {
      summary: {
        usersLast7Days: null,
        devices: null,
        users: null,
        reports: null,
        messages: null,
        counts: null,
      },

      usersPerDayData: null,
      usersPerDayLabels: null,

      customersPerCitySeries: null,
      customersPerCityLabels: null,

      cutomersPerPhoneOSSeries: null,
      cutomersPerPhoneOSLabels: null,

      boundDevicesChartData: null,
    };
  },
  async mounted() {
    await axios
      .get("dashboard/counts")
      .then((res) => (this.summary.counts = res.data))
      .catch();

    await axios
      .get("dashboard/users-last7days")
      .then((res) => {
        this.summary.users = {
          labels: res.data.users.labels,
          series: [
            {
              data: res.data.users.series,
              name: this.$t("user", 2),
            },
          ],
        };
        this.summary.devices = {
          labels: res.data.devices.labels,
          series: [
            {
              data: res.data.devices.series,
              name: this.$t("device", 2),
            },
          ],
        };
        this.summary.reports = {
          labels: res.data.reports.labels,
          series: [
            {
              data: res.data.reports.series,
              name: this.$t("report", 2),
            },
          ],
        };
        this.summary.messages = {
          labels: res.data.messages.labels,
          series: [
            {
              data: res.data.messages.series,
              name: this.$t("message", 2),
            },
          ],
        };
      })
      .catch((err) => console.log(err));

    await axios
      .get("dashboard/users-per-day")
      .then((res) => {
        (this.usersPerDayData = res.data.data),
          (this.usersPerDayLabels = res.data.labels);
      })
      .catch((err) => console.error(err));

    await axios
      .get('dashboard/bound-devices')
      .then(res => {
        this.boundDevicesChartData = {
          series: [
            {
              name: this.$t("total"),
              data: res.data.total,
            },
            {
              name: this.$t("bound"),
              data: res.data.bound,
            }
          ],
          labels: res.data.labels
        }
      })
      .catch((err) => console.error(err));

    await axios
      .get("dashboard/customers-by-city")
      .then((res) => {
        this.customersPerCitySeries = [
          {
            name: this.$t("mattress"),
            data: res.data.mattress,
          },
          {
            name: this.$t("sleepaid"),
            data: res.data.sleepaid,
          },
        ];
        this.customersPerCityLabels = res.data.labels.map(city => {
          return city == '' ? 'Unknown' : city
        });
      })
      .catch((err) => console.error(err));

    await axios
      .get("dashboard/customers-by-phone")
      .then((res) => {
        // console.log(res.data.data.map((e) => Number(e)));
        this.cutomersPerPhoneOSSeries = res.data.data.map((e) => Number(e));
        this.cutomersPerPhoneOSLabels = res.data.labels.map((item) =>
          item == "1" ? this.$t("android") : this.$t("ios")
        );
      })
      .catch((err) => console.error(err));
  },
};
</script>